var mapSDK = function(parameters){
	function checkElseDefault(value,d)
 	{
 		return (typeof(value) === 'undefined') ? d : value;
 	};
        

	var _this = this;
	parameters = parameters || {};
	parameters.vg = parameters.vg || {};
	parameters.google = parameters.google || {};
	var b = parameters.vg;
	var g = parameters.google;
	var baseUrl = CONFIG["BASE_URL"];
	var apiUrl = CONFIG["API_URL"];
	var enable_map_labels = typeof(CONFIG["ENABLE_MAP_LABELS"]) === 'undefined'?false:CONFIG["ENABLE_MAP_LABELS"];
	var mapviewer;
	var currentNavigation;
	var currentRoute;
	var stages;
	var staffFilters;
	var routeData;
	var translator = (typeof(parameters.translator) === 'undefined')?new AppTranslator('en_us',{}):parameters.translator;
	var mapURL = checkElseDefault(b.vgMapUrl,CONFIG["VG_MAP_BUNDLE"]);
	var ggMap;
	var vgMapContainerId = checkElseDefault(b.vgMapContainerId,CONFIG["VG_CONTAINER_ID"]);
	var ggMapContainerId = checkElseDefault(g.ggMapContainerId,CONFIG["GG_CONTAINER_ID"]);
	var vgInitialView = 'detail'; //detail or building
	var vgMapStatus = 'UNLOAD'; //UNLOAD,LOADING,LOADED,STARTED
	var ggMapLoaded = false;
	var initialFloorName = checkElseDefault(b.initialFloorName,CONFIG["VG_INITIAL_FLOOR_NAME"]);
	var initialMapId = checkElseDefault(b.initialMapId,CONFIG["VG_INITIAL_MAP_ID"]);
	var initialGGMapPosition = checkElseDefault(g.initialLatLong,CONFIG["GG_INIT_LAT_LNG"]);
    var overlayPoly= g.overlayPoly;
	var floorCenterPosition;
	var defaultCameraRadius = checkElseDefault(b.defaultCameraRadius,CONFIG["VG_DEFAULT_CAM_RADIUS"]);
	var defaultBuildingCameraRadius = checkElseDefault(b.defaultBuildingCameraRadius,CONFIG["VG_DEFAULT_BUILD_CAM_RADIUS"]);
	var centerCameraRadius = checkElseDefault(b.centerCameraRadius,CONFIG["VG_CENTER_CAM_RADIUS"]);
	var defaultFloorCameraPitch = checkElseDefault(b.defaultFloorCameraPitch,CONFIG["VG_DEFAULT_FLOOR_CAM_PITCH"]);
	var ggDefaultCameraZoom = checkElseDefault(g.defaultCameraZoom,CONFIG["GG_DEFAULT_CAM_ZOOM"]);
	var ggDefaultMaxZoom = checkElseDefault(g.defaultMaxZoom,CONFIG["GG_DEFAULT_MAX_ZOOM"]);
	var categories = CATEGORIES.getAllCategoriesArray();
	var outCategories = CATEGORIES.getOutdoorCategoriesArray();
	var allCategoriesClasses = CATEGORIES.getAllCategoriesClasses();
	var cxtData = {'departments':{}};
	var allCxtData = [];
	var outCxtData={};
	var buildings = {};
	var classifications={};
	var institutions ={};
	var sites={};
	var waypoints={};
	var pplanners={};
	var floors = [];
	var settings = [];
	var time_exclusions = [];
	var directionsUseDistance = true;
	var enableLandmarks = false;
	var pendingVGTasks = [];
	var deferredChangeFloor;
	//Use to find a connexient place when click on any area of the map ia available
	var mapIdsToData = {};
	var floorToPois = {};
	//This object is require by MyNavigation class to change map_ids to labels , using the same file format as ids.json: {"targets":["default"],"labels":{"UL0-ID0003":["UL0-ID0003","Zara"],...} }
	var vgIds = {"targets":["default"],"labels":{}}
	var destObj = null;
	var originObj = null;
	//var placePoi;
	//var yhaPoi;
	var overlaysPOI = {};
	var selectedPOI = CONFIG["DEFAULT_CATEGORIES_SELECTED"];
	var selectedOutdoorPOI = [];
	var mapView = checkElseDefault(parameters.defaultMapView,'outdoor');
	var googleController;
	var routePoint=null;
	// var googleEndPoint = {
	// 	map_id:CONFIG["DEFAULT_ENTRANCE_MAPID"],
	// 	lat:CONFIG["GG_INIT_LAT_LNG"].lat,
	// 	lng:CONFIG["GG_INIT_LAT_LNG"].lng,
	// 	name:CONFIG["DEFAULT_ENTRANCE_NAME"],
	// 	waypoints:[]}
	var complexRoute;
	var routeTransportationMode = 'driving';
	var hasShuttle = CONFIG["HAS_SHUTTLE"];
	var totalDBFiles = 0;
	var counterDBFiles = 0;
	var destinationLib = null;
	var isRouting = false;
	var handicapRoute = false;

	//Initialize the map SDK for connexient
	this.init = function()
	{
		if(isWebGLSupported()){
			loadConnexientData();
			loadGGMap(showCampusMap);
			// loadVGMap();
		}else{
			$('body').removeClass('loading').addClass('no-webgl-support');
		}
	} 

	this.getData = function(){
		return cxtData;
	}

	this.getCategories = function(){
		return categories;
	}

	this.getAllCategoriesClasses = function() {
		return allCategoriesClasses;
	}

	this.getCategoryType = function(type){
		return $.grep(categories,function(a){
			return (a.type===type)
		});
	}

	this.getCategoryById = function(catId){
		for(var i=0 ; i < categories.length; i++){
			var obj = categories[i];
			if(String(catId) === String(obj.id)){
				return obj;
			}
		}
		return null;
	}

	this.getOutCategoryById = function(catId){
		for(var i=0 ; i < outCategories.length; i++){
			var obj = outCategories[i];
			if(String(catId) === String(obj.id)){
				return obj;
			}
		}
		return null;
	}

	this.getCategoryByName = function(name){
		var arr = $.grep(categories, function( f ) {
		  return f.name === name;
		});
		return arr[0];
	}

	this.getDestination = function(){
		return destObj;
	}
	this.getOrigin = function(){
		return originObj;
	}

	this.getApiUrl = function(){
		return apiUrl;
	}

	this.getFloors = function(){
		return floors;
	}

	this.getSetting = function(pName){
 		return settings[0][pName];
 	}

	this.getBuildings = function(){
		return buildings;
	}

	this.getCurrentFloor = function(){
		return multiFloorApp.getCurrentFloorOverride();
	}

	this.getCurrentExploreState = function(){
		if(multiBuildingView){
			return multiBuildingView.getCurrentExploreState();
		}else{
			return null;
		}
	}

	this.getMapView = function(){
		return mapView;
	}

	this.getVgMapStatus = function(){
		return vgMapStatus;
	}

	this.setModeTransportation = function(mode){
		routeTransportationMode = mode;
	}

	this.getNavigationMode = function(){
		if (typeof(currentNavigation) == 'object' && currentNavigation !== null) {
			return currentNavigation.getNavigationMode();
		}else{
			return false;
		}
	}
	
	this.getDefaultEntrance = function() {
		var defaultEntrance = mapIdsToData[CONFIG['DEFAULT_ENTRANCE_MAPID']];
		defaultEntrance = mapApp.getData()['entrance'][defaultEntrance.position];
		return defaultEntrance;
	}
	
	this.getPreferedParking=function(){
		$.each(pplanners,function(i,f){
			if(f.is_prefered === 1){
				return f;
			}
		});
		return pplanners[0];
	}

	this.getPreferedParkingByMapId = function(pkMapId){
		var p = pplanners[0];
		$.each(pplanners,function(i,f){
			if(f.map_id === pkMapId){
				p = f;
			}
		});
		return p;
	}

	this.getTranslatorTags = function(){
		return translator;
	}

	/* Compute if the route is indoor-outdoor or outdoor-indoor */
	this.getRouteDirection = function(){
		var routeway = 'indoor-indoor';
		var orgIndoorAddress;
		var destIndoorAddress;
		if(originObj.category==='outdoor' && destObj.category==='outdoor'){
			routeway = 'outdoor-outdoor';
		}else if(originObj.category!=='outdoor' && destObj.category!=='outdoor'){
			orgIndoorAddress = originObj.buildingAddress[originObj.toAddress];
			destIndoorAddress = destObj.buildingAddress[destObj.toAddress];
			routeway = (orgIndoorAddress!==null && orgIndoorAddress['prime'])?'indoor-':'outdoor-';
			routeway += (destIndoorAddress!==null && destIndoorAddress['prime'])?'indoor':'outdoor';
		}else if(originObj.category==='outdoor'){
			destIndoorAddress = destObj.buildingAddress[destObj.toAddress];
			routeway = (destIndoorAddress!==null && destIndoorAddress['prime'])?'outdoor-indoor':'outdoor-outdoor';
		}else if(destObj.category==='outdoor'){
			orgIndoorAddress = originObj.buildingAddress[originObj.toAddress];
			routeway = (orgIndoorAddress!==null && orgIndoorAddress['prime'])?'indoor-outdoor':'outdoor-outdoor';
		}
		return routeway;
	}

	this.getIndoorAddress = function (pRoutePoint){
		for(var i=0; i<pRoutePoint.buildingAddress.length;i++){
			if(pRoutePoint.buildingAddress[i]['prime']){
				return pRoutePoint.buildingAddress[i];
				break;
			}
		}
		return null;
	}

	this.clearRouteWaypoints = function(){
		routePoint = null;
	}

	this.addRouteWaypoint= function(params){
		var point;
		var pos=params.position;
		var mode = params.mode;
		var category = params.category;
		switch(mode){
			case 'driving':
			pos = params.position;
			if(category==='entrance'){
				point = mapApp.getData()[category][pos];
				point = mapApp.getOutdoorEntranceDetails(point.map_id);
				var location = mapviewer.getPlace(point.map_id);
				var coordinates = mapviewer.convertPointToLatLon(location.vg.position);
				point.lat=coordinates.lat;
				point.lng=coordinates.lon;
			}else{
				point = pplanners[pos];
				var waypoints = getWaypoints(point.map_id);
				if(waypoints){
					point['waypoints']=waypoints;
				}
				if (point.lat===0 ||point.lng===0) {
					var location = mapviewer.getPlace(pplanners[pos].map_id)
					var coordinates = mapviewer.convertPointToLatLon(location.vg.position)
					point.lat=coordinates.lat;
					point.lng=coordinates.lon;
				}
			}
			break;
			case 'transit':
			case 'walking':
			point = mapApp.getDestination()['mapId']?getClosestEntrance(mapApp.getDestination()['mapId']):getClosestEntrance(mapApp.getOrigin()['mapId']);
			var location = mapviewer.getPlace(point.map_id)
			var coordinates = mapviewer.convertPointToLatLon(location.vg.position)
			point.lat=coordinates.lat;
			point.lng=coordinates.lon;
			break;
		}
		routePoint = point;
	}
	
	function getClosestEntrance(pMapId){
		var i =0;
		var closestPIndex = -1;
		var closestDistance = 0;
		var indoorPoint = mapviewer.getPlace(pMapId);
		var entranceCat = mapApp.getData()['entrance'];
		if(entranceCat && Object.keys(entranceCat).length>0){
			$.each( entranceCat, function( key, pp ) {
				var ppPlace = mapviewer.getPlace(pp.map_id);
				if(ppPlace){
					var dt = mapviewer.computeDistance(ppPlace.vg.position,indoorPoint.vg.position);
					if(dt <= closestDistance || i === 0){
						closestDistance = dt;
						closestPIndex = pp.position;
					}
					i++;
				}
			});
		}
		if(closestPIndex !== -1){
			var pp = mapApp.getData()['entrance'][closestPIndex];//outdoorEntrances[closestPIndex];
			return pp;
		}
		return null;
	}

	function getWaypoints(mapId){
		return waypoints[mapId];
	}

	this.getRouteWaypoint =function(){
		return routePoint;
	}

	this.getOutdoorEntranceDetails = function(mapId){
		var entrances = _this.getData()['entrance'];
		var length = Object.keys(entrances).length;
		for(var i=0; i < length; i++){
			var e = entrances[i];
			if(e.map_id == mapId){
				return e;
			}
		}
		return null;
	}

	this.getFloorByIndex = function(index){
		var arr = $.grep(floors, function( f ) {
		  return f.map_floor === index;
		});
		return arr[0];
	}

	function showCampusMap () {
		mapApp.changeFloor({target:"global",index:CONFIG["VG_INITIAL_FLOOR_NAME"],building:CONFIG["VG_INITIAL_BUILDING_NAME"]})
	}

	function loadGGMap(callback){
		var customMapType = new google.maps.StyledMapType([
		      {
		        featureType: 'poi.medical',
		        stylers: [{visibility: 'simplified'}]
		      },
		      {
		      	elementType: 'labels',
		        featureType: 'poi',
		        stylers: [{visibility: 'off'}]
		      }
		    ], {
		      name: 'Custom Style'
		  });
		var customMapTypeId = 'custom_style';
		ggMap = new google.maps.Map(document.getElementById('gg_map_container'), {
		    center: {lat: initialGGMapPosition.lat, lng: initialGGMapPosition.lng},
		    zoom: ggDefaultCameraZoom,
                    streetViewControl: false,
		    mapTypeControl:false,
		    zoomControl: false,
		    maxZoom:ggDefaultMaxZoom,
		    mapTypeControlOptions: {
		      mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId]
		    }
		  });
		google.maps.event.addListener(ggMap, clickEvent, function(event) {
		    console.log(event.latLng.lat());
		    console.log(event.latLng.lng());
		});

		google.maps.event.addListener(ggMap, 'projection_changed', function(event) {
		   // addGGOverlay(overlayPoly,ggMap);
		});
		google.maps.event.addDomListener(window, "resize", function() {
		    var center = ggMap.getCenter();
		    google.maps.event.trigger(ggMap, "resize");
		    ggMap.setCenter(center); 
		});
		ggMap.mapTypes.set(customMapTypeId, customMapType);
  		ggMap.setMapTypeId(customMapTypeId);
  		ggMapLoaded = true;
  		googleController = new GoogleAPIController({map:ggMap,center:initialGGMapPosition,hasShuttle:hasShuttle,translator:translator});
  		(typeof callback === 'function') ? callback() : displayGGMap();
	}

	this.changeFloor = function(pParams){
		deferredChangeFloor = jQuery.Deferred();
		var result = deferredChangeFloor.promise();
		executeChangeFloor(pParams);
		return result;
	}

	function executeChangeFloor(pParams){
		var currentState = mapApp.getCurrentExploreState();
		var target = pParams.target;
		var index = pParams.index;
		var building = pParams.building;
		var isGoogleMode = $('body').hasClass('gg-map');
		if(target === 'outdoor'){
			//Show outdoor map
			displayGGMap();
		}else if(target === 'building' || target === 'floor' || target === 'global'){
			if(vgMapStatus==='STARTED'){
				if(currentState.mode === 'floor' && target === 'floor'){
					changeVGFloor(index);
				}else if(target === 'building' && building!=='outside'){
					displayBuildingMap({building:building});
				}else if((currentState.mode === 'floor' || isGoogleMode || currentState.mode === 'building') && target === 'global'||building === "outside"){
					displayGlobalMap({centerMap:pParams.centerMap});
				}else if(target==='floor'){
					displayFloorMap({updateFloor:true,floorName:index});
				}
			}else{
				pendingVGTasks.push({process:executeChangeFloor,context:_this,args:[{target:pParams.target,index:pParams.index,building:pParams.building}]});
				loadVGMap();
			}
		}
	}

	function displayGlobalMap(params){
		var centerPosition =_this.getFloorCenterPosition();
		var currentState = multiBuildingView.getCurrentExploreState();
		var centerMap = params.centerMap;
		if(vgMapStatus === 'STARTED'){
			var goToParameters = {
				mode: 'global',
			};
			if(centerMap){
				goToParameters.viewpoint = {
					position: {x:centerPosition.x,y:centerPosition.y,radius:defaultCameraRadius},
				}
			}
			multiBuildingView.goTo(goToParameters).done(function(){
				mapView = 'global';
				if(deferredChangeFloor){deferredChangeFloor.resolve();}
			});
			$('body').addClass('vg-map').removeClass('gg-map loading');
		}else{
			$('body').addClass('vg-map loading welcome').removeClass('gg-map');
			//vgInitialView = 'building';
			pendingVGTasks.push({process:displayGlobalMap,context:_this});
			loadVGMap();;
		}
	}

	function changeVGFloor(index){
		multiBuildingView.goTo({
            mode: 'floor',
            floorID: index,
            animationDuration: 0
        }).done(function(){
        	$('body').addClass('vg-map').removeClass('gg-map');
			if(deferredChangeFloor){deferredChangeFloor.resolve();}
        });
	}

	this.updateCameraPitch = function(value){
		mapviewer.camera.pitch = value;
	}
	this.checkDisconnectedPlaces = function () {
	//use the defaultStartPoint entrance as origin point, can be changed to test other places
	console.log(mapApp.getData()["entrance"]);
	var defaultStartPoint = Object.keys(mapApp.getData()["entrance"]).map(function (e) {
		return mapApp.getData()["entrance"][e];
	}).find(function (e) {
		return e.map_id == CONFIG.VG_INITIAL_MAP_ID;
	});
	var indoorData = [];
	Object.keys(mapApp.getData()).forEach((category) => {
		Object.keys(mapApp.getData()[category]).forEach((place) => {
			indoorData.push(mapApp.getData()[category][place]);
		})
	});
	for (var i = 0; i < indoorData.length; i++) {
		console.log('testing ' + indoorData[i].map_id + ' ' + indoorData[i].name);
		mapviewer.computeRoute({
			dst: indoorData[i].map_id,
			src: defaultStartPoint.map_id,
			computeNavigation: true,
			navigationParameters: {
				"firstNodeAsIntersection": false,
				"mergeFloorChangeInstructions": false
			}
		}).then(function (data) {
			// console.log(data);
			if (data.data.status !== 200) {
				console.log(data.data);
				console.error(data.data.error + ' from ' + data.data.request.src + ' to ' + data.data.request.dst);
			}
		}).catch(function (err) {
			console.error('Cannot compute route from ' + err.src + ' to ' + err.dst);
		});
	}
};

	this.getCameraPitch = function(){
		return mapviewer.camera.pitch;
	}

	this.getInstitutionById = function(id){
		return institutions[id];
	}
	this.getBuildingById = function(id){
		for(var i=0; i < buildings.length; i++){
			var b = buildings[i];
			if(b.id == id){
				return b;
			}
		}
		return null;
	}
	this.getSiteById = function(id){
		return sites[id];
	}
	this.getClassificationById = function(id){
		return classifications[id];
	}
	this.clearOriginPoint = function(){
		originObj = null;
	}
	this.clearDestinationPoint = function(){
		removeDestinationLib();
		removeDestinationLibOutdoor();
		destObj = null;
	}

	function removeDestinationLib(){
		if(destObj && typeof(destinationLib)!== 'undefined' && destinationLib !== null && destObj.mapType==='vg'){
			var destinationMarkerSelector = 'destination-marker-ctn';
			removelibObj(destinationLib,destinationMarkerSelector);
			destinationLib = null;
		}
	}

	function removeDestinationLibOutdoor(){
		if (destObj && destObj.mapType==='gg'){
			googleController.removeDestinationLibOutdoor();
		}
	}

	this.disableFloorNavPanel = function(){
		$("#change_view_select").addClass('disabled');
		$("#change_building_select").addClass('disabled');
		$("#change_floor_select").addClass('disabled');
	}

	this.enableFloorNavPanel = function(){
		$("#change_view_select").removeClass('disabled');
		$("#change_building_select").removeClass('disabled');
		$("#change_floor_select").removeClass('disabled');
	}
	
	this.checkClosedEntrances = function(destObjId){
		var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
		var todayIs = days[new Date().getDay()];
		for (var el in time_exclusions) {
			if (time_exclusions[el].hospital_hotspot_id==destObjId && time_exclusions[el].day_of_week==todayIs) {
				var currentHour = new Date().toLocaleTimeString('en-US', { hour12: false});
				// var currentHour = '20:50:00';
				if (currentHour > time_exclusions[el].open_time && currentHour < time_exclusions[el].close_time) {
					return false;
				}else {
					$("#closedEntranceModal").modal()
					return true;
				}
			}
		}
	}
	
	this.addOriginPoint = function(pPoint){
		var obj;
		var cat = pPoint.category;
		var pos = pPoint.position;
		var id = pPoint.id;
		if(pPoint.map_type==='gg'){
			if(cat === 'parking_planner'){
				obj=_this.findParkingPlannerByPos({position:pos});
				obj.map_type = 'gg';
				obj['toAddress'] = pPoint.toAddress;
				originObj = new RoutePoint(obj);
			}else if(cat === 'entrance_planner'){
				obj=_this.getOutdoorEntranceDetails(mapId);
				obj.map_type = 'gg';
				obj['toAddress'] = pPoint.toAddress;
				originObj = new RoutePoint(obj);
			}else if(cat !== 'outdoor' && cat !== 'parking_planner' && cat !== 'entrance_planner'){
				obj=_this.findOutCxtDataByPos({category:cat,position:pos});
				obj['toAddress'] = pPoint.toAddress;
				originObj = new RoutePoint(obj);	
			}else{
				originObj = new RoutePoint({position:pos,id:id,category:cat,map_type:'gg'});
			}
		}else{
			obj=findCxtDataByPos({category:cat,position:pos});//cxtData[cat][pos];
			obj['toAddress'] = pPoint.toAddress;
			originObj = new RoutePoint(obj);
		}
	}
	this.addDestinationPoint = function(pPoint){
		var obj;
		var cat = pPoint.category;
		var pos = pPoint.position;
		var id = pPoint.id;
		if(pPoint.map_type==='gg'){
			if(cat === 'parking_planner'){
				obj=_this.findParkingPlannerByPos({position:pos});
				obj.map_type = 'gg';
				obj['toAddress'] = pPoint.toAddress;
				destObj = new RoutePoint(obj);
			}else if(cat === 'entrance_planner'){
				obj=_this.getOutdoorEntranceDetails(mapId);
				obj.map_type = 'gg';
				obj['toAddress'] = pPoint.toAddress;
				destObj = new RoutePoint(obj);
			}else if(cat !== 'outdoor' && cat !== 'parking_planner' && cat !== 'entrance_planner'){
				obj=_this.findOutCxtDataByPos({category:cat,position:pos});
				obj['toAddress'] = pPoint.toAddress;
				destObj = new RoutePoint(obj);
			}else{
				destObj = new RoutePoint({position:pos,id:id,category:cat,map_type:'gg'});
			}
		}else{
			obj=findCxtDataByPos({category:cat,position:pos});//cxtData[cat][pos];
			obj['toAddress'] = pPoint.toAddress;
			destObj = new RoutePoint(obj);
		}
	}

	this.editRoute = function(){
		var obj;
		if(originObj.mapType==='vg'){
			obj=findCxtDataByPos({category:originObj.category,position:originObj.position});
			obj['toAddress'] = originObj.toAddress;
			originObj = new RoutePoint(obj);
		}
		if(destObj.mapType==='vg'){
			obj=findCxtDataByPos({category:destObj.category,position:destObj.position});
			obj['toAddress'] = destObj.toAddress;
			destObj = new RoutePoint(obj);
		}
	}

	this.getHandicapRoute = function() {
 		return handicapRoute;
 	}

 	this.setHandicapRoute = function(handicap) {
 		handicapRoute = handicap;
 	}

	//Trigger on a shuttle route POI is clicked
	this.goToStageFromOutdoorPOI = function(poiKey){
		var result = -1;
		if (typeof(currentNavigation) == 'object' && currentNavigation !== null && !$(this).hasClass('disabled')) {
			//removeYhaPoi();
			result = currentNavigation.goToShuttleStageFromPOI(poiKey);
		}
		return result;
	}

	function setOutdoorIndoorLegs(pParams){
		var mapLegs=[];
		var outStart = pParams.outStart;
		var outEnd = pParams.outEnd;
		var breakPoint = pParams.routeBreakPoint;
		var inStart = pParams.inStart;
		var inEnd = pParams.inEnd;
		var waypoints = pParams.waypoints;

		mapLegs.push({
			start:{category:outStart.category,name:outStart.name,lat:outStart.lat,lng:outStart.lng},
			end:{category:outEnd.category,name:outEnd.name,lat:outEnd.lat,lng:outEnd.lng},
			type:'outdoor',
			mode:routeTransportationMode,
			waypoints:waypoints,
			iconStart:true,
			iconEnd:(routeTransportationMode === 'driving' && breakPoint.prime === 0)?false:true,
			addShuttle:(hasShuttle && routeTransportationMode === 'transit')?true:false
		});
		if(routeTransportationMode === 'driving' && breakPoint.prime === 0){
			//When parking is in the outdoor, user should first go to the aprking and then walk to Main Entrance
			//indoorStartAddress = mainEntrance;
			mapLegs.push({
				start:{category:breakPoint.category,name:breakPoint.name,lat:breakPoint.lat,lng:breakPoint.lng},
				end:{category:inStart.category,name:inStart.name,lat:inStart.lat,lng:inStart.lng},
				type:'outdoor',
				mode:'walking',
				waypoints:waypoints,
				iconStart:false,
				iconEnd:true
			});
		}
		if(routeTransportationMode === 'transit' && hasShuttle){
			mapLegs.push({
				type:'shuttle',
				mode:'walking',
				direction:'outdoor-indoor'
			});
		}
		mapLegs.push({
			start:{map_id:inStart.map_id,name:inStart.name},
			end:{map_id:inEnd.map_id,category:inEnd.category,name:inEnd.name},
			type:'indoor',
			mode:'walking'
		});

		return mapLegs;
	}

	function setIndoorOutdoorLegs(pParams){
		var mapLegs=[];
		var outStart = pParams.outStart;
		var outEnd = pParams.outEnd;
		var breakPoint = pParams.routeBreakPoint;
		var inStart = pParams.inStart;
		var inEnd = pParams.inEnd;
		var waypoints = pParams.waypoints;

		mapLegs.push({
			start:{map_id:inStart.map_id,category:inStart.category,name:inStart.name},
			end:{map_id:inEnd.map_id,category:inEnd.category,name:inEnd.name},
			type:'indoor',
			mode:'walking'
		});
		if(routeTransportationMode === 'driving' && breakPoint.prime === 0){
			//When parking is in the outdoor, user should first go to the aprking and then walk to Main Entrance
			mapLegs.push({
				start:{category:inEnd.category,name:inEnd.name,lat:inEnd.lat,lng:inEnd.lng},
				end:{category:breakPoint.category,name:breakPoint.name,lat:breakPoint.lat,lng:breakPoint.lng},
				type:'outdoor',
				mode:'walking',
				waypoints:waypoints,
				iconStart:true,
				iconEnd:false
			});
		}
		if(routeTransportationMode === 'transit' && hasShuttle){
			mapLegs.push({
				type:'shuttle',
				mode:'walking',
				direction:'indoor-outdoor'
			});
		}
		mapLegs.push({
			start:{name:outStart.name,lat:outStart.lat,lng:outStart.lng},
			end:{category:outEnd.category,name:outEnd.name,lat:outEnd.lat,lng:outEnd.lng},
			type:'outdoor',
			mode:routeTransportationMode,
			iconStart:(routeTransportationMode === 'driving' && breakPoint.prime === 0)?false:true,
			iconEnd:true,
			addShuttle:(hasShuttle && routeTransportationMode === 'transit')?true:false
		});

		return mapLegs;
	}

	function setOutdoorOutdoorLegs(pParams){
		var mapLegs=[];
		var outStart = pParams.outStart;
		var outEnd = pParams.outEnd;
		var waypoints = pParams.waypoints;
		var extraStage = pParams.extraStage;

		mapLegs.push({
			start:{name:outStart.name,lat:outStart.lat,lng:outStart.lng},
			end:{name:outEnd.name,lat:outEnd.lat,lng:outEnd.lng},
			type:'outdoor',
			mode:routeTransportationMode,
			iconStart:true,
			iconEnd:true,
			waypoints:waypoints,
			addShuttle:(hasShuttle && extraStage)?true:false
		});

		if(extraStage){
			var shuttleStage = {
				type:'shuttle',
				mode:'walking',
				direction:extraStage.direction
			}
			if(extraStage.position === 0){
				mapLegs.unshift(shuttleStage);
			}else{
				mapLegs.push(shuttleStage);
			}
		}
		return mapLegs;
	}

	function checkRoutePoints(){
		var outdoorWaypoints = [];
		var routePointMapId;
		var extraStage;
		var routeDirection = _this.getRouteDirection();
		var startMapId = (originObj.mapType === 'vg')?originObj.buildingAddress[originObj.toAddress]['map_id']:'';
		var endMapId = (destObj.mapType === 'vg')?destObj.buildingAddress[destObj.toAddress]['map_id']:'';
		
		if(routeDirection === 'indoor-outdoor'){
			routePointMapId = routePoint.map_id;
			if(startMapId === routePointMapId){
				var adIn = originObj.buildingAddress;
				var addressIn = adIn[originObj.toAddress];
				addressIn.prime=false;
				addressIn.lat=routePoint.lat;
				addressIn.lng=routePoint.lng;
				if(routeTransportationMode === 'transit' && hasShuttle){
					extraStage = {
						position:0,
						direction:routeDirection
					}
				}
			}
		}else if(routeDirection === 'outdoor-indoor'){
			routePointMapId = routePoint.map_id;
			if(endMapId === routePointMapId){
				var adOut = destObj.buildingAddress;
				var addressOut = adOut[destObj.toAddress];
				addressOut.prime=false;
				addressOut.lat=routePoint.lat;
				addressOut.lng=routePoint.lng;
				outdoorWaypoints = routePoint.waypoints;
			}
		}

		if(routeTransportationMode === 'transit' && hasShuttle && (routeDirection === 'outdoor-indoor' || routeDirection === 'indoor-outdoor')){
			extraStage = {
				position:(routeDirection==='indoor-outdoor')?0:1,
				direction:routeDirection
			}
		}

		return {waypoints:outdoorWaypoints,extraStage:extraStage};
	}

	//TODO:This function should consider when the root has path from diferrent buildings
	//TODO:Check if it's important to get the outdoor place from google map API after searching a location using predictions
	//   a lot of if are caused because of it
	function buildMapStages(){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var checkRouteData;
		var indoorStartPoint;
		var indoorEndPoint;
		var outdoorStartAddress;
		var outdoorEndAddress;
		var indoorStartAddress;
		var indoorEndAddress;
		var mainEntrance = _this.getOutdoorEntranceDetails(CONFIG['DEFAULT_ENTRANCE_MAPID']);
		var routeDirection = _this.getRouteDirection();
		var mapLegs=[];

		//When parking garage or entrance is selected as route point on the way out or in. And the start point 
		// or end point is the same the route should be only indoor or outdoor
		checkRouteData = checkRoutePoints();
		routeDirection = _this.getRouteDirection();

		if(routeDirection === 'indoor-indoor'){
			var indoorStartAddress = _this.getIndoorAddress(originObj);
			var indoorEndAddress = _this.getIndoorAddress(destObj);
			var initMapId = indoorStartAddress.map_id;
			var endMapId = indoorEndAddress.map_id;
			mapLegs.push({
				start:{map_id:initMapId,category:originObj.category,name:originObj.name},
				end:{map_id:endMapId,category:destObj.category,name:destObj.name},
				type:'indoor',
				mode:'walking'
			});
			deferred.resolve({stages:mapLegs,direction:routeDirection});
		}else if(routeDirection === 'outdoor-indoor'){
			indoorStartPoint = (routeTransportationMode === 'driving' && routePoint.prime === 0)?mainEntrance:routePoint;
			indoorEndAddress = destObj.buildingAddress[destObj.toAddress];
			if(originObj.mapType === 'gg' && originObj.category!== 'outdoor'){
				outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];

				mapLegs = setOutdoorIndoorLegs({
					outStart:{
						category:originObj.category,
						name:originObj.name,
						lat:outdoorStartAddress.lat,
						lng:outdoorStartAddress.lng
					},
					outEnd:{
						category:routePoint.category,
						name:routePoint.name,
						lat:routePoint.lat,
						lng:routePoint.lng
					},
					inStart:{
						category:indoorStartPoint.category,
						name:indoorStartPoint.name,
						map_id:indoorStartPoint.map_id,
						lat:indoorStartPoint.lat,
						lng:indoorStartPoint.lng
					},
					inEnd:{
						category:destObj.category,
						name:destObj.name,
						map_id:indoorEndAddress.map_id
					},
					routeBreakPoint:routePoint,
					waypoints:routePoint.waypoints
				});
				deferred.resolve({stages:mapLegs,direction:routeDirection});
			}else if(originObj.mapType === 'gg'){
				googleController.getPlace(originObj.id).then(function(place){
					convertPlaceToCXTData(originObj,place);
					outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];

					mapLegs = setOutdoorIndoorLegs({
						outStart:{
							category:originObj.category,
							name:originObj.name,
							lat:outdoorStartAddress.lat,
							lng:outdoorStartAddress.lng
						},
						outEnd:{
							category:routePoint.category,
							name:routePoint.name,
							lat:routePoint.lat,
							lng:routePoint.lng
						},
						inStart:{
							category:indoorStartPoint.category,
							name:indoorStartPoint.name,
							map_id:indoorStartPoint.map_id,
							lat:indoorStartPoint.lat,
							lng:indoorStartPoint.lng
						},
						inEnd:{
							category:destObj.category,
							name:destObj.name,
							map_id:indoorEndAddress.map_id
						},
						routeBreakPoint:routePoint,
						waypoints:routePoint.waypoints
					});
					deferred.resolve({stages:mapLegs,direction:routeDirection});
				});
			}else{
				outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];
				mapLegs = setOutdoorIndoorLegs({
					outStart:{
						category:originObj.category,
						name:originObj.name,
						lat:outdoorStartAddress.lat,
						lng:outdoorStartAddress.lng
					},
					outEnd:{
						category:routePoint.category,
						name:routePoint.name,
						lat:routePoint.lat,
						lng:routePoint.lng
					},
					inStart:{
						category:indoorStartPoint.category,
						name:indoorStartPoint.name,
						map_id:indoorStartPoint.map_id,
						lat:indoorStartPoint.lat,
						lng:indoorStartPoint.lng
					},
					inEnd:{
						category:destObj.category,
						name:destObj.name,
						map_id:indoorEndAddress.map_id
					},
					routeBreakPoint:routePoint,
					waypoints:routePoint.waypoints
				});
				deferred.resolve({stages:mapLegs,direction:routeDirection});
			}
			
		}else if(routeDirection === 'indoor-outdoor'){
			indoorStartAddress = originObj.buildingAddress[originObj.toAddress];
			indoorEndPoint = (routeTransportationMode === 'driving' && routePoint.prime === 0)?mainEntrance:routePoint;
			if(destObj.mapType === 'gg' && destObj.category !== 'outdoor'){
				outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

				mapLegs = setIndoorOutdoorLegs({
					outStart:{
						category:routePoint.category,
						name:routePoint.name,
						lat:routePoint.lat,
						lng:routePoint.lng
					},
					outEnd:{
						category:destObj.category,
						name:destObj.name,
						lat:outdoorEndAddress.lat,
						lng:outdoorEndAddress.lng
					},
					inStart:{
						category:originObj.category,
						name:originObj.name,
						map_id:indoorStartAddress.map_id
					},
					inEnd:{
						category:indoorEndPoint.category,
						name:indoorEndPoint.name,
						map_id:indoorEndPoint.map_id,
						lat:indoorEndPoint.lat,
						lng:indoorEndPoint.lng
					},
					routeBreakPoint:routePoint,
					waypoints:routePoint.waypoints
				});
				deferred.resolve({stages:mapLegs,direction:routeDirection});
			}else if(destObj.mapType === 'gg'){
				googleController.getPlace(destObj.id).then(function(place){
					convertPlaceToCXTData(destObj,place);
					outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

					mapLegs = setIndoorOutdoorLegs({
						outStart:{
							category:routePoint.category,
							name:routePoint.name,
							lat:routePoint.lat,
							lng:routePoint.lng
						},
						outEnd:{
							category:destObj.category,
							name:destObj.name,
							lat:outdoorEndAddress.lat,
							lng:outdoorEndAddress.lng
						},
						inStart:{
							category:originObj.category,
							name:originObj.name,
							map_id:indoorStartAddress.map_id
						},
						inEnd:{
							category:indoorEndPoint.category,
							name:indoorEndPoint.name,
							map_id:indoorEndPoint.map_id,
							lat:indoorEndPoint.lat,
							lng:indoorEndPoint.lng
						},
						routeBreakPoint:routePoint,
						waypoints:routePoint.waypoints
					});
					deferred.resolve({stages:mapLegs,direction:routeDirection});
				});
			}else{
				outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];
				mapLegs = setIndoorOutdoorLegs({
					outStart:{
						category:routePoint.category,
						name:routePoint.name,
						lat:routePoint.lat,
						lng:routePoint.lng
					},
					outEnd:{
						category:destObj.category,
						name:destObj.name,
						lat:outdoorEndAddress.lat,
						lng:outdoorEndAddress.lng
					},
					inStart:{
						category:originObj.category,
						name:originObj.name,
						map_id:indoorStartAddress.map_id
					},
					inEnd:{
						category:indoorEndPoint.category,
						name:indoorEndPoint.name,
						map_id:indoorEndPoint.map_id,
						lat:indoorEndPoint.lat,
						lng:indoorEndPoint.lng
					},
					routeBreakPoint:routePoint,
					waypoints:routePoint.waypoints
				});
				deferred.resolve({stages:mapLegs,direction:routeDirection});
			}
			
		}else if(routeDirection === 'outdoor-outdoor'){
			if(originObj.mapType === 'gg' && originObj.category!=='outdoor' && destObj.mapType==='gg'){
				
				if(destObj.category !=='outdoor') {
					outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];
					outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

					mapLegs = setOutdoorOutdoorLegs({
						outStart:{
							category:originObj.category,
							name:originObj.name,
							lat:outdoorStartAddress.lat,
							lng:outdoorStartAddress.lng
						},
						outEnd:{
							category:destObj.category,
							name:destObj.name,
							lat:outdoorEndAddress.lat,
							lng:outdoorEndAddress.lng
						},
						waypoints:checkRouteData.waypoints,
						extraStage:checkRouteData.extraStage
					});
					deferred.resolve({stages:mapLegs,direction:routeDirection});
				}else{
					googleController.getPlace(destObj.id).then(function(place){
						convertPlaceToCXTData(destObj,place);
						outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];
						outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

						mapLegs = setOutdoorOutdoorLegs({
							outStart:{
								category:originObj.category,
								name:originObj.name,
								lat:outdoorStartAddress.lat,
								lng:outdoorStartAddress.lng
							},
							outEnd:{
								category:destObj.category,
								name:destObj.name,
								lat:outdoorEndAddress.lat,
								lng:outdoorEndAddress.lng
							},
							waypoints:checkRouteData.waypoints,
							extraStage:checkRouteData.extraStage
						});
						deferred.resolve({stages:mapLegs,direction:routeDirection});
					});
				}
			}else if(originObj.mapType === 'gg' && destObj.category!=='outdoor' && destObj.mapType==='gg'){
				googleController.getPlace(originObj.id).then(function(place){
					convertPlaceToCXTData(originObj,place);
					//googleController.getPlace(destObj.id).then(function(place){
						//convertPlaceToCXTData(destObj,place);
						outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];
						outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

						mapLegs = setOutdoorOutdoorLegs({
							outStart:{
								category:originObj.category,
								name:originObj.name,
								lat:outdoorStartAddress.lat,
								lng:outdoorStartAddress.lng
							},
							outEnd:{
								category:destObj.category,
								name:destObj.name,
								lat:outdoorEndAddress.lat,
								lng:outdoorEndAddress.lng
							},
							waypoints:checkRouteData.waypoints,
							extraStage:checkRouteData.extraStage
						});
						deferred.resolve({stages:mapLegs,direction:routeDirection});
					//});
				});
			}else if(originObj.mapType === 'gg' && destObj.mapType==='gg'){
				googleController.getPlace(originObj.id).then(function(place){
					convertPlaceToCXTData(originObj,place);
					googleController.getPlace(destObj.id).then(function(place){
						convertPlaceToCXTData(destObj,place);
						outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];
						outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

						mapLegs = setOutdoorOutdoorLegs({
							outStart:{
								category:originObj.category,
								name:originObj.name,
								lat:outdoorStartAddress.lat,
								lng:outdoorStartAddress.lng
							},
							outEnd:{
								category:destObj.category,
								name:destObj.name,
								lat:outdoorEndAddress.lat,
								lng:outdoorEndAddress.lng
							},
							waypoints:checkRouteData.waypoints,
							extraStage:checkRouteData.extraStage
						});
						deferred.resolve({stages:mapLegs,direction:routeDirection});
					});
				});
			}else if(originObj.mapType === 'vg' && destObj.mapType==='gg' && destObj.category!=='outdoor'){
				outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];

				//googleController.getPlace(destObj.id).then(function(place){
					//convertPlaceToCXTData(destObj,place);
					outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

					mapLegs = setOutdoorOutdoorLegs({
						outStart:{
							category:originObj.category,
							name:originObj.name,
							lat:outdoorStartAddress.lat,
							lng:outdoorStartAddress.lng
						},
						outEnd:{
							category:destObj.category,
							name:destObj.name,
							lat:outdoorEndAddress.lat,
							lng:outdoorEndAddress.lng
						},
						waypoints:checkRouteData.waypoints,
						extraStage:checkRouteData.extraStage
					});
					deferred.resolve({stages:mapLegs,direction:routeDirection});
				//});
			}else if(originObj.mapType === 'vg' && destObj.mapType==='gg'){
				outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];

				googleController.getPlace(destObj.id).then(function(place){
					convertPlaceToCXTData(destObj,place);
					outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

					mapLegs = setOutdoorOutdoorLegs({
						outStart:{
							category:originObj.category,
							name:originObj.name,
							lat:outdoorStartAddress.lat,
							lng:outdoorStartAddress.lng
						},
						outEnd:{
							category:destObj.category,
							name:destObj.name,
							lat:outdoorEndAddress.lat,
							lng:outdoorEndAddress.lng
						},
						waypoints:checkRouteData.waypoints,
						extraStage:checkRouteData.extraStage
					});
					deferred.resolve({stages:mapLegs,direction:routeDirection});
				});
			}else if(originObj.mapType === 'gg' && originObj.category!=='outdoor' && destObj.mapType==='vg'){
				outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

				//googleController.getPlace(originObj.id).then(function(place){
				//	convertPlaceToCXTData(originObj,place);
					outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];
					
					mapLegs = setOutdoorOutdoorLegs({
						outStart:{
							category:originObj.category,
							name:originObj.name,
							lat:outdoorStartAddress.lat,
							lng:outdoorStartAddress.lng
						},
						outEnd:{
							category:destObj.category,
							name:destObj.name,
							lat:outdoorEndAddress.lat,
							lng:outdoorEndAddress.lng
						},
						waypoints:checkRouteData.waypoints,
						extraStage:checkRouteData.extraStage
					});
					deferred.resolve({stages:mapLegs,direction:routeDirection});
				//});
			}else if(originObj.mapType === 'gg' && destObj.mapType==='vg'){
				outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

				googleController.getPlace(originObj.id).then(function(place){
					convertPlaceToCXTData(originObj,place);
					outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];
					
					mapLegs = setOutdoorOutdoorLegs({
						outStart:{
							category:originObj.category,
							name:originObj.name,
							lat:outdoorStartAddress.lat,
							lng:outdoorStartAddress.lng
						},
						outEnd:{
							category:destObj.category,
							name:destObj.name,
							lat:outdoorEndAddress.lat,
							lng:outdoorEndAddress.lng
						},
						waypoints:checkRouteData.waypoints,
						extraStage:checkRouteData.extraStage
					});
					deferred.resolve({stages:mapLegs,direction:routeDirection});
				});
			}else{
				outdoorStartAddress = originObj.buildingAddress[originObj.toAddress];
				outdoorEndAddress = destObj.buildingAddress[destObj.toAddress];

				mapLegs = setOutdoorOutdoorLegs({
					outStart:{
						category:originObj.category,
						name:originObj.name,
						lat:outdoorStartAddress.lat,
						lng:outdoorStartAddress.lng
					},
					outEnd:{
						category:destObj.category,
						name:destObj.name,
						lat:outdoorEndAddress.lat,
						lng:outdoorEndAddress.lng
					},
					waypoints:checkRouteData.waypoints,
					extraStage:checkRouteData.extraStage
				});
				deferred.resolve({stages:mapLegs,direction:routeDirection});
			}
			
		}else{
			console.error('Invalid origin and destination points');
			deferred.reject({message:'Invalid routeDirection'});
		}

		return result;
	}

	function fillHeaderPanels(){
		var startPoint = originObj;
		var endPoint = destObj;
		var startAddress = (startPoint.category==='outdoor')?startPoint.buildingAddress[0]['building_address']:generateShortAddress(startPoint,false); 
		var destAddress = (endPoint.category==='outdoor')?endPoint.buildingAddress[0]['building_address']:generateShortAddress(endPoint,false); 
		$('.header-instructions.start .name').html(startPoint.name);
		$('.header-instructions.start .address').html(startAddress);
		$('.header-instructions.end .name').html(endPoint.name);
		$('.header-instructions.end .address').html(destAddress);
	}

	this.computeRoute = function(){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var routeLegs = [];

		buildMapStages().then(function(data){
			stages = data.stages;
			var routeDirection = data.direction;
			var c_params = {
				start:originObj,
				end:destObj,
				direction:routeDirection,
				hasShuttle:hasShuttle,
				legs:stages,
				mergeFloorChangeInstructions:false,
				translator:translator,
				vgIds:vgIds,
				mapviewer:mapviewer,
				googleController:googleController,
				modeTransportation:routeTransportationMode,
				floors:floors,
				buildings:buildings,
				directionsUseDistance:directionsUseDistance,
				enableLandmarks:enableLandmarks
			}
			complexRoute = new ComplexRoute(c_params);
			var handicap = _this.getHandicapRoute();
			complexRoute.computeRoute(handicap).then(function(pStages){
				console.log(pStages);
				fillHeaderPanels();
				currentNavigation = new MyNavigation(mapviewer,complexRoute,vgIds,floors,googleController,translator,multiBuildingView,{});
				_this.displayStage(0);
				deferred.resolve();
			}).fail(function(status){
				_this.clearRoute();
				deferred.reject({message: status.message});
			});
		});
		removeDestinationLib();
		removeDestinationLibOutdoor();
		return result;
	}

	function convertPlaceToCXTData(cxtObj,place){
		cxtObj.name = place.name;
		var address = cxtObj.buildingAddress;
		address.push({
			lat:place.geometry.location.lat(),
			lng:place.geometry.location.lng(),
			building_address:place.formatted_address,
			prime:false
		});
		cxtObj.buildingAddress = address;

	}

	function displayGGMap(){
		if(ggMapLoaded){
			$('body').addClass('gg-map').removeClass('vg-map loading');
			//The explore bar is always close for outside map
			$('.right-omnibox .explore-bar').removeClass('expand');

			var modeSelector = jQuery('#change_view_select');
			var modeElem = modeSelector.find('.views-container li[data-target="outdoor"]');
			modeSelector.find('#map-view').html(modeElem.attr('data-text'));

			mapView = 'outdoor';
			googleController.center(initialGGMapPosition);
			googleController.setZoom(ggDefaultCameraZoom);
			if(deferredChangeFloor){deferredChangeFloor.resolve();}
		}
	}

	function displayBuildingMap(params){
		var currentState = multiBuildingView.getCurrentExploreState();
		if(vgMapStatus === 'STARTED'){
			//if(currentState.mode === 'floor' || currentState.mode === 'global' || currentState.mode === 'building'){
				var oldBuildingState = multiBuildingView.getBuildingRememberState(params.building);
				multiBuildingView.goTo({
	                mode: 'building',
	                buildingID: params.building,
	                floorID: VgMultiBuildingView.DEFAULT//(oldBuildingState)?oldBuildingState:VgMultiBuildingView.DEFAULT
	            }).done(function(){
	        		mapView = 'building';
	            	if(deferredChangeFloor){deferredChangeFloor.resolve();}
	            }).fail(function(){
	            	console.error('Error while changing building: '+params.building);
	            	if(deferredChangeFloor){deferredChangeFloor.resolve();}
	            });
			//}
			$('body').addClass('vg-map').removeClass('gg-map loading');
		}else{
			$('body').addClass('vg-map loading welcome').removeClass('gg-map');
			//vgInitialView = 'building';
			pendingVGTasks.push({process:displayBuildingMap,context:_this});
			loadVGMap();;
		}
	}

	function displayFloorMap(params){
		mapView = 'site';
		if(vgMapStatus === 'STARTED'){
			var updateFloor = (params.updateFloor)?params.updateFloor:false;
			var index = (params.floorName)?params.floorName:initialFloorName;
			if(multiBuildingView.getCurrentExploreState() === 'floor' && Boolean(updateFloor)){	
				changeVGFloor(index);
			}else{
				if(Boolean(updateFloor)){
					changeVGFloor(index);
				}else{
					if(deferredChangeFloor){deferredChangeFloor.resolve();}
				}
			}
			$('body').addClass('vg-map').removeClass('gg-map loading welcome');
		}else{
			$('body').addClass('vg-map loading welcome').removeClass('gg-map');
			pendingVGTasks.push({process:displayFloorMap,context:_this,args:params});
			loadVGMap();
		}
	}


	this.zoomIn = function(){
		if(mapView==='outdoor'){
			googleController.zoomIn();
		}else{
			var pos = mapviewer.camera.position;
	        pos.radius = pos.radius*0.5;
	        mapviewer.camera.position = pos;
		} 
	}

	this.zoomOut = function(){
		if(mapView==='outdoor'){
			googleController.zoomOut();
		}else{
			var pos = mapviewer.camera.position;
		    pos.radius = pos.radius/0.5;
		    mapviewer.camera.position = pos;
		}  
	}

	this.fillParkingList = function(params){
		var category = params.category;
		var cont = $(params.container);
		cont.html('');
		cont.append('<ul></ul>');
		cont = cont.find('ul');
		var list = (category==='parking')?pplanners:cxtData[category];
		$.each(list,function(i,park){
			var litmp = '';
			litmp+='<li class="list-item" data-item-pos="'+park.position+'" data-map-id="'+park.map_id+'" data-cat="'+category+'" data-item-text="'+replaceSpecialCharactersInString(park.name)+'">'+park.name+'</li>';
			cont.append(litmp);
		});
		var onMap='<li class="list-item on-map" data-cat="'+category+'">'+translator.getLangLabel('VIEW_ON_MAP')+'</li>';
		cont.append(onMap);
	}

	this.drawFilterList = function(params){
		var categoryType = (params.categoryType)?params.categoryType:null;
		var extraClass = (params.extraClass)?params.extraClass:'';
		var cont = $(params.container);
		cont.html('');

		if(categoryType){
			cont.append('<ul></ul>');
			cont = cont.find('ul');
			cont.addClass(extraClass);
			var categories = (categoryType==='all')?_this.getCategories():_this.getCategoryType(categoryType);
			$.each(categories,function(i,cat){
				var litmp = '';
				litmp+='<li class="list-item outer" data-action="'+cat.name+'"><span class="icon red '+cat.name+'"></span>';
				litmp+= translator.getLangLabel(cat.label) + '<span class="icon arrow"></span>';
				litmp+='<div class="inner-div '+cat.name+'">';
				litmp+='</div>'
				litmp+='</li>'
				cont.append(litmp);
			});
		}else{
			params['page'] = 0;
			addItemsToSearchPanel(params);
		}
	}
	function replaceSpecialCharactersInString(str) {
		return str.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
	}

	//TODO: Customize iterations to prevent repeated code
	function addItemsToSearchPanel(params) {
		if(params.category != "" || params.filterName != "") {
			var rex = (params.filterName && params.filterName.length > 0) ? new RegExp(params.filterName, 'i') : new RegExp('.', 'i');
			var site = (params.site && params.site != null) ? new RegExp('^' + params.site + '$', 'i') : new RegExp('[\s\S]*', 'i');
			var building = (params.building && params.building != null) ? new RegExp('^' + params.building + '$', 'i') : new RegExp('[\s\S]*', 'i');
			var institution = (params.institution && params.institution != null) ? new RegExp('^' + params.institution + '$', 'i') : new RegExp('[\s\S]*', 'i');
			var clas = new RegExp('[\s\S]*', 'i');
			var categoryType = (params.categoryType) ? params.categoryType : null;
			var categoryName = (params.category && !categoryType) ? params.category : 'all';
			var cont = $(params.container);
		
			if (params.classification && params.classification != null && params.classification.length > 0) {
				var s = '(';
				s += params.classification.join('|');
				s += ')';
				clas = new RegExp(s, 'i');
			}
		
			if (params.classification && params.classification != null && params.classification.length > 0) {
				var s = '(';
				s += params.classification.join('|');
				s += ')';
				clas = new RegExp(s, 'i');
			}
		
			if (categoryName === 'all') {
				items = allCxtData;
				if (items) {
					endIndex = items.length;
				}
			} else {
				items = (cxtData[categoryName] ? Object.keys(cxtData[categoryName]).map(function (i) {
					return cxtData[categoryName][i];
				}) : []).concat(outCxtData[categoryName] ? Object.keys(outCxtData[categoryName]).map(function (i) {
					return outCxtData[categoryName][i];
				}) : []);
		
				if (items) {
					scrollPanel = indoorElementsUL;
					endIndex = Object.keys(items).length;
				}
			}

			// show Physicians only if address exists
			function checkAddressForStaff(item) {
				var addressExists = false;
				for (var i = 0; i < item.building_address.length; i++) {
					var addr = item.building_address[i];
					if (!!addr.map_id && !!addr.building_map_id && !!addr.building_id && !!addr.floor_number && !!addr.floor
						&& !!addr.vg_floor_id && addr.prime === true
						|| addr.prime === false && !!addr.building_address &&!!addr.lat  && !!addr.lng) {
						addressExists = true;
						break;
					}
				}
				return addressExists;
			}
		
			function filter(item) {
				categoryName = item.category;
				var cat = _this.getCategoryByName(categoryName);
				if (cat) {
					if (categoryName === 'staff' && (rex.test(item.name) && checkAddressForStaff(item) || filterTags(item, rex)) && hasFilterBuilding(item, building) && institution.test(item.organization_id) && hasFilterSite(item, site)/*site.test(item.site)*/ && clas.test(item.classification_id)) {
						return true;
					} else if (categoryName !== 'staff' && (rex.test(item.name) || filterTags(item, rex))) {
						return true;
					}
				}
				else {
					return false;
				}
			}
		
			if (items) {
				var separatedItems = {
					'indoor': items.filter(function (item) {
						return item.building_address[0].prime && filter(item);
					}).sort(function(a, b) {
						return a.name > b.name ? 1 : (a.name < b.name ? -1 : 0);
					}),
					'outdoor': items.filter(function (item) {
						return !item.building_address[0].prime && filter(item);
					}).sort(function(a, b) {
						return a.name > b.name ? 1 : (a.name < b.name ? -1 : 0);
					}),
				};
		
				$('.data-panel').addClass("open");
				cont.html('');

				cont.append('<div class="list-item-title">' + CONFIG["SHORT_HOSPITAL_NAME"] + ' ' + translator.getLangLabel('ON_CAMPUS_LOCATIONS') + '</div>');
				cont.append('<ul class="indoor"></ul>');

				cont.append('<div class="list-item-title">' + translator.getLangLabel('OFF_CAMPUS_LOCATIONS') + '</div>');
				cont.append('<ul class="outdoor"></ul>');
		
				var indoorElementsUL = cont.find('ul.indoor');
				var outdoorElementsUL = cont.find('ul.outdoor');
		
				if (separatedItems.indoor.length > 0) {
					separatedItems.indoor.forEach(function (item, i) {
						categoryName = item.category;
						var cat = _this.getCategoryByName(categoryName);
						if (cat) {
							if (categoryName === 'staff' && (rex.test(item.name) || filterTags(item, rex)) && hasFilterBuilding(item, building) && institution.test(item.organization_id) && hasFilterSite(item, site)/*site.test(item.site)*/ && clas.test(item.classification_id)) {
								indoorElementsUL.append('<li class="list-item" data-item-pos="' + item.position + '" data-item-id="' + item.id + '" data-map-id="' + item.map_id + '" data-cat="' + cat.name + '" data-item-text="' + replaceSpecialCharactersInString(item.name) + '" data-map-type="' + item.map_type + '">' + item.name + '</li>');
							} else if (categoryName !== 'staff' && (rex.test(item.name) || filterTags(item, rex))) {
								var startField = document.getElementById('start-point');
								var endField = document.getElementById('end-point');
								if ((startField && document.activeElement === startField && startField.value.trim() === '') ||
									(endField && document.activeElement === endField && endField.value.trim() === '')) {
									$('.location-filter').html('');
								} else {
									indoorElementsUL.append('<li class="list-item" data-item-pos="' + item.position + '" data-item-id="' + item.id + '" data-map-id="' + item.map_id + '" data-cat="' + cat.name + '" data-item-text="' + replaceSpecialCharactersInString(item.name) + '" data-map-type="' + item.map_type + '">' + item.name + '</li>');
								}
							}
						}
					});
				}
				else {
					if (separatedItems.indoor.length == 0) {
						indoorElementsUL.append('<li class="empty-item">' + translator.getLangLabel('NO_RESULTS_FOUND') + '</li>');
					}
				}
		
				if (separatedItems.outdoor.length > 0) {
					separatedItems.outdoor.forEach(function (item, i) {
						categoryName = item.category;
						var cat = _this.getCategoryByName(categoryName);
						if (cat) {
							if (categoryName === 'staff' && (rex.test(item.name) || filterTags(item, rex)) && hasFilterBuilding(item, building) && institution.test(item.organization_id) && hasFilterSite(item, site)/*site.test(item.site)*/ && clas.test(item.classification_id)) {
								outdoorElementsUL.append('<li class="list-item" data-item-pos="' + item.position + '" data-item-id="' + item.id + '" data-map-id="' + item.map_id + '" data-cat="' + cat.name + '" data-item-text="' + replaceSpecialCharactersInString(item.name) + '" data-map-type="' + item.map_type + '">' + item.name + '</li>');
							} else if (categoryName !== 'staff' && (rex.test(item.name) || filterTags(item, rex))) {
								outdoorElementsUL.append('<li class="list-item" data-item-pos="' + item.position + '" data-item-id="' + item.id + '" data-map-id="' + item.map_id + '" data-cat="' + cat.name + '" data-item-text="' + replaceSpecialCharactersInString(item.name) + '" data-map-type="' + item.map_type + '">' + item.name + '</li>');
							}
						}
					});
				}
				else {
					var startField = document.getElementById('start-point');
					var endField = document.getElementById('end-point');
					if ((startField && document.activeElement === startField && startField.value.trim() === '') ||
						(endField && document.activeElement === endField && endField.value.trim() === '')) {
						$('.location-filter').html('');
					} else {
						if ((endField.value !== '' || startField.value !== '') && separatedItems.outdoor.length === 0) {
							outdoorElementsUL.append('<li class="empty-item">' + translator.getLangLabel('NO_RESULTS_FOUND') + '</li>');
						}
					}
				}
			} else {
				indoorElementsUL.append('<li class="empty-item">' + translator.getLangLabel('NO_RESULTS_FOUND') + '</li>');
				$('.data-panel').removeClass("open");
			}
		}
	}

	function filterTags(pItem,pRegex){
		var filterTags = false;
		for(var i=0;i<pItem.tags.length; i++){
			if(pRegex.test(pItem.tags[i])){
				filterTags = true;
				break;
			}
		}
		return filterTags;
	}

	function hasFilterBuilding(pItem,pRegexBuilding){
		var hasFilter = false;
		for(var i=0;i<pItem.building_address.length; i++){
			if(pRegexBuilding.test(pItem.building_address[i].building_id)){
				hasFilter = true;
				break;
			}
		}
		return hasFilter;
	}

	function hasFilterSite(pItem,pRegexSite){
		var hasFilter = false;
		for(var i=0;i<pItem.building_address.length; i++){
			if(pRegexSite.test(pItem.building_address[i].site)){
				hasFilter = true;
				break;
			}
		}
		return hasFilter;
	}

	function scrollSearchPanel(params){
		var cont = $(params.container);
		cont = cont.find('ul');
		var scrollPanel = cont;
		if(scrollPanel.scrollTop() + scrollPanel.height() >= scrollPanel[0].scrollHeight) {
    
    		params.page = params.page+1;
    		addItemsToSearchPanel(params);

		}
	}

	this.fillInfoCard = function(){
		if(destObj){
			pushDataToCard(destObj,destObj.category);
		}else{
			console.log('Invalid destination point');
		}
	}

	this.drawFilterData = function(){
		if($('#filter-loaded').val()==='false'){
			var cont = $('#filter-building').parent().find('.dropdown-menu');
			cont.html('');
			$.each(buildings,function(i,b){
				cont.append('<li class="filter-item"><a href="#" data-type="building" data-id="'+b.id+'">'+b.name+'</a></li>');
			});
			// Commented by request of BAYCARE-370
			// cont = $('#filter-institution').parent().find('.dropdown-menu');
			// cont.html('');
			// $.each(institutions,function(i,b){
			// 	cont.append('<li class="filter-item"><a href="#" data-type="institution" data-id="'+b.id+'">'+b.name+'</a></li>');
			// });
			// cont = $('#filter-site').parent().find('.dropdown-menu');
			// cont.html('');
			// $.each(sites,function(i,b){
			// 	cont.append('<li class="filter-item"><a href="#" data-type="site" data-id="'+b.id+'">'+b.name+'</a></li>');
			// });
			// cont = $('#filter-class');
			// var tempcont = cont.find('.left');
			// tempcont.html('');
			// $.each(classifications,function(i,b){
			// 	if(i == 4){
			// 		tempcont = cont.find('.right');
			// 		tempcont.html('');
			// 	}
			// 	tempcont.append('<div class="checkbox"><label><input type="checkbox" name="classification" data-type="classification" data-id="'+b.id+'"></input>'+b.name+'</label></div>');
			// });
			$('#filter-loaded').val('true');
		}else{
			if(staffFilters){
				if(!staffFilters.institution || staffFilters.institution==null){
					resetDropdownStaffFilter('institution');
				}
				if(!staffFilters.building || staffFilters.building==null){
					resetDropdownStaffFilter('building');
				}
				if(!staffFilters.site || staffFilters.site==null){
					resetDropdownStaffFilter('site');
				}
				var checks = $('#filter-form input[type="checkbox"]').filter(function( index ) {
				    return $(this).attr( "data-id" ) !== '0';
				}).removeAttr('checked');
				if(staffFilters['classification'] && staffFilters.classification !== null && staffFilters.classification.length > 0){
					$.each(staffFilters.classification,function(key,value){
						$('#filter-form input[type="checkbox"][data-id="'+value.id+'"]').prop('checked',true);
					});
				}
			}
		}
	}

	function resetDropdownStaffFilter(pFilter){
		var b = $('#filter-form .field-form .btn[data-filter="'+pFilter+'"]');
		b.html(b.data('default')+'<span class="caret"></span>');
		b.val(b.data('default'));
		b.attr('data-id','');
	}

	/*this.mapViewModeChanged = function(){
		//TODO::We need a way to verify if we are showing the vg map
		if($('body').hasClass('vg-map')){
			if (multiFloorApp.isInDetailedView())
			{
				mapView = 'site';
			}
			else
			{
				mapView = 'building';
			}
		}
	}*/

	this.addPOIs = function (mapType, category) {
		switch(mapType){
			case 'indoor':
			addPoisLibs(category);
			break;
			case 'outdoor':
			addOutdoorPois(category);
			break;
		}
	}

	this.removePOIs = function(mapType,category){
		switch(mapType){
			case 'indoor':
			removePoisLibs(category);
			break;
			case 'outdoor':
			removeOutdoorPois(category);
			break;
		}
	}

	function addPoisLibs(type){
		//var centerPosition = _this.getFloorCenterPosition();
		var exploreMode = multiBuildingView.getCurrentExploreState();
		var currentFloor = exploreMode.floorID;
		var poisList = exploreMode.mode==='global'?floorToPois['outside']:floorToPois[currentFloor];
		selectedPOI.push(type);
		if(typeof(poisList)!= "undefined" && poisList!=null){
			poisList = $.grep(poisList,function(a){
				return (a.category===type)
			});
			if(exploreMode.mode === 'floor'||'global'){
				console.log({poisList});
				iterateAndDrawIndoorPois(poisList);
			}else{
				multiBuildingView.goTo({
					mode: 'floor',
					buildingID: exploreMode.buildingID,
					floorID: exploreMode.floorID
				}).done(function(){
					iterateAndDrawIndoorPois(poisList);
				});
			}
		}
	}

	function deactivateIndoorPoisInExploreBar() {
		var exploreBarItems = $('.explore-bar .poi-item.indoor span');
		$.each(exploreBarItems, function (key, value) {
			$(value).removeClass('active');
		});
	}

	function iterateAndDrawIndoorPois(poisList){
		$.each(poisList,function(key,value){
			var poi = cxtData[value.category][value.position];
			var poiPoint = new RoutePoint(poi);
			var indoorAddress = mapApp.getIndoorAddress(poiPoint);
			var poiMapId = indoorAddress.map_id;
			if(poiMapId){
				var options = {
					category:value.category,
					name:poiPoint.name,
					id:poiPoint.id,
					mapId:poiMapId,
					position:poiPoint.position
				}
				var p = _this.drawMapLib(options);
				if(p){
					pushOverlayPoi(p);
				}
			}
		});
	}

	function removePoisLibs(type){
		if(overlaysPOI[type]){
			$.each(overlaysPOI[type],function(key,value){
				value.options('visible', false, false);
				// value.remove();
			});
		}
		overlaysPOI[type]={};
		selectedPOI = $.grep(selectedPOI,function(a){
			return (a!==type);
		});
	}

	function showPoisLibs(pParams){
		deactivateIndoorPoisInExploreBar();
		var exploreMode = multiBuildingView.getCurrentExploreState();
		//var currentFloor = exploreMode.floorID;
		var currentFloor = pParams.floorID;
		if(selectedPOI){
			$.each(selectedPOI,function(key,type){
				var poisList = exploreMode.mode==='global'?floorToPois['outside']:floorToPois[currentFloor];
				if(typeof(poisList)!= "undefined" && poisList!=null){
					poisList = $.grep(poisList,function(a){
						return (a.category===type)
					});
					iterateAndDrawIndoorPois(poisList);
				}
			});
		}	
	}

	function hidePoisLibs(){
		if(selectedPOI){
			$.each(selectedPOI,function(k,type){
				if(overlaysPOI[type]){
					$.each(overlaysPOI[type],function(key,value){
						value.options('visible', false, false);
						// value.remove();
					});
				}
			});
		}
	}

	//TODO:Parking Garages and Entrances should be outdoor POIs
	function addOutdoorPois(type){
		var poi;
		switch(type){
			/*case 'entrance':
			poi = _this.getData()['entrance'];
			addOutdoorMarkers(type,poi);
			break;*/
            case 'shuttle':
            googleController.getShuttleStops().done(function(stops){
            	poi = stops;
            	addOutdoorMarkers(type,poi);
            });
            break;
			default:
			poi = allCxtData.filter((function(p) {
				return p.category == type;
			}));
			addOutdoorMarkers(type,poi);
			break;
		}
		
	}

	function addOutdoorMarkers(type,poiList){
		selectedOutdoorPOI.push(type);
		$.each(poiList,function(i,obj){
			//if(obj.map_type && obj.map_type === 'gg'){
				obj['lat'] = obj.building_address[0]['lat'];
				obj['lng'] = obj.building_address[0]['lng'];
			//}
			googleController.addPoiMarker(type,obj);
		});
	}
        
	function removeOutdoorPois(type){
		googleController.removePoiMarkers(type);
		selectedOutdoorPOI = $.grep(selectedOutdoorPOI,function(a){
			return (a!==type);
		});
	}

	this.drawMapLib = function(options){
		var isNew = true;
		var poi = null;
		var category = options.category;
		var mapId = options.mapId;
		var poictn = $('<div>').html($('#map-lib-template').html());
		var lib = null;
		var selector = category+'-'+mapId;
		selector = selector.toLowerCase();
		var ent = mapviewer.getPlace(mapId);

		if($('#'+selector).length > 0){
			isNew = false;
			lib = $('#'+selector);
		}else{
			lib = poictn.find('.lib');
			lib.attr('id',selector);
		}

		var item = $('.explore-bar .poi-item.indoor .' + category);
		if (item) item.addClass('active');
		lib.attr('data-label',options.name);
		lib.addClass(category);
		lib.attr('data-id',options.id);
		lib.attr('data-cat',category);
		lib.attr('data-map-id',mapId);
		lib.attr('data-pos',options.position);
		lib.find('.small-ctn .icon').addClass('lib-'+category);

		if(ent){
			poi = {selector:selector,category:category};
			var pos = {x: ent.vg.position.x, y: ent.vg.position.y, radius: mapviewer.camera.position.radius};
			if(Boolean(isNew)){
				$('#hidden-div').append(poictn.html());
			}
			poi.obj= mapviewer.addPOI({selector: '#'+selector, floor: ent.vg.floor, position: pos, scale: 12, alignment: {x: 0, y: 1.0}});
			hookMapLibEvents(poi.obj,selector);
		}else{
			console.log('Can\'t find place:'+mapId);
		}
		return poi;
	}

	function displayLibWindow(lib,category,libWindowOuterHeight=70){
		let id = lib.attr('id');
		let libWindowId = id+'-window';
		let libWindow;
		if($('#'+libWindowId).length > 0){
			libWindow = $('#'+libWindowId);
			let currentLib = $('#'+id);
			let currentLabel= currentLib[0].dataset.label
			libWindow.find('span').attr('class', 'icon libWindow-'+category)
			libWindow.attr('class', 'big-ctn '+category)
			libWindow.find('.label').html(currentLabel);
			libWindow.css('top',-libWindow.outerHeight());
		}else{
			libWindow = $($('#map-libWindow-template').html());
			let label = lib.data('label');
			libWindow.addClass(category);
			libWindow.find('.label').html(label);
			libWindow.find('.icon').addClass('libWindow-'+category);
			libWindow.attr('id',libWindowId);
			lib.prepend(libWindow);
			libWindow.css('top',-libWindow.outerHeight());
		}
		lib.css('z-index', '1');
		libWindow.show();
	}

	function hideLibWindow(lib){
		var id = lib.attr('id');
		var libWindowId = id+'-window';
		lib.css('z-index', '0');
		$('#'+libWindowId).hide();
	}

	this.getFloorCenterPosition = function(){
		if(!floorCenterPosition){
			floorCenterPosition = mapviewer.getPlace(initialMapId).vg.position;
		}
		return floorCenterPosition;
	}

	this.getStaffFromId = function(staff_id){

		var staff = $.map(cxtData['staff'], function(value, index) {
		    return [value];
		});

		var result = staff.filter(function(obj) {
		  return obj.id.toString() == staff_id.toString();
		});

		return result;

	}

	this.setStaffFilters = function(params){
		staffFilters = params;
	}
	this.getStaffFilters = function(params){
		return staffFilters;
	}

	this.clearStaffFilters = function(){
		staffFilters = {};
	}

	this.removeStaffFilter = function(params){
		var type = params.type;
		var id = params.id;
		switch(type){
			case "classification":
			var institutions = staffFilters[type];
			institutions = $.grep(institutions,function(a){
				return (a.id!==id);
			});
			staffFilters[type] = institutions;
			break;
			default:
			staffFilters[type] = null;
		}
	}
	this.displayPrevInstruction = function(){
		if (typeof(currentNavigation) == 'object' && currentNavigation !== null && !$(this).hasClass('disabled')) {
			//removeYhaPoi();
			currentNavigation.displayPrevInstruction();
		}	
	}
	this.displayNextInstruction = function(){
		if (typeof(currentNavigation) == 'object' && currentNavigation !== null && !$(this).hasClass('disabled')) {
			//removeYhaPoi();
			currentNavigation.displayNextInstruction();
		}
	}
	this.displayPrevStage = function(){
		if (typeof(currentNavigation) == 'object' && currentNavigation !== null && !$(this).hasClass('disabled')) {
			//removeYhaPoi();
			currentNavigation.displayPrevStage();
		}	
	}
	this.displayNextStage = function(){
		if (typeof(currentNavigation) == 'object' && currentNavigation !== null && !$(this).hasClass('disabled')) {
			//removeYhaPoi();
			currentNavigation.displayNextStage();
		}
	}
	this.displayStage = function(index){
		if (typeof(currentNavigation) == 'object' && currentNavigation !== null && !$(this).hasClass('disabled')) {
			//removeYhaPoi();
			currentNavigation.setNavigationMode('preview');
			currentNavigation.gotoStage(index);
		}
	}
	this.gotoInstruction = function(index){
		if(typeof(currentNavigation) == 'object' && currentNavigation !== null){
			//removeYhaPoi();
			currentNavigation.setNavigationMode('step-by-step');
			//currentNavigation.loadStepByStepInstructions();
			currentNavigation.gotoInstruction(index);
		}
	}
	this.clearRoute = function(centerMapParam){
		//removeYhaPoi();
		
		var centerMap;
		if (typeof(centerMapParam) === 'undefined'){
			centerMap = true;
		}else{
			centerMap = centerMapParam;
		}

		if (complexRoute != null) {
			complexRoute.remove();
		}
		if(currentNavigation!=null){
			currentNavigation.remove();
		}

		if(centerMap){
			googleController.center(initialGGMapPosition);
		}
		googleController.setZoom(ggDefaultCameraZoom);

		_this.clearRouteWaypoints();

		$('body').removeClass('routing');
	}

	/*
	function removeYhaPoi(){
		if(yhaPoi){
			yhaPoi.hide();
			yhaPoi.remove();
			$('#yha-lib').remove();
		}
	}
	*/

	this.setCenterGG = function(latLng) {
		googleController.center(latLng);
	}

	this.setCenterPosition = function(){
		var centerPosition =_this.getFloorCenterPosition();
		var goToParameters = {
	          mode: 'floor',
	          floorID:mapviewer.getCurrentFloor(),
	          viewpoint: {
	              position: {x:centerPosition.x,y:centerPosition.y,radius:defaultCameraRadius},
	              heading: 0
	           }
	      };
	      multiBuildingView.goTo(goToParameters);
	}

	this.searchStreetAddress =function(params){
		var cont = $(params.container);
		cont.html('');
		var results = googleController.getPlacePredictions(params.filterName).then(function(data){
			if(data){
				cont.append('<ul></ul>');
				cont = cont.find('ul');
				$.each(data,function(i,place){
					cont.append('<li class="list-item" data-item-pos="'+i+'" data-item-id="'+place.place_id+'" data-map-id="" data-cat="outdoor" data-item-text="'+replaceSpecialCharactersInString(place.description)+'" data-map-type="gg">'+place.description+'</li>');
				});
			}
		});
	}

	this.drawDestinationLibOutdoor = function(obj){
		if(obj !== undefined && obj !== null){
			if(obj.category !== 'outdoor'){
				googleController.drawDestinationLibOutdoor(obj);
			}else{
				googleController.getPlace(obj.id).then(function(place){
					convertPlaceToCXTData(obj,place);
					googleController.drawDestinationLibOutdoor(obj);
				});
			}
		}
	}

	this.drawDestinationLib = function(){
		var isRouting = $('body').hasClass('routing');
		if (destObj && destObj.mapType === 'vg' && !isRouting){ 
			var exploreMode = multiBuildingView.getCurrentExploreState();
			var currentFloor = exploreMode.floorID;
			var indoorAddress = _this.getIndoorAddress(destObj);
			if (indoorAddress.vg_floor_id === currentFloor || indoorAddress.vg_floor_id ==='outside'){
				var isNew = true;
				var mapId = indoorAddress.map_id;
				var category = destObj.category;
				var ent = mapviewer.getPlace(mapId);
				var markerTemplate = $('<div>').html($('#map-lib-template').html());
				var selector = 'destination-marker-ctn';
				if($('#'+selector).length > 0){
					isNew = false;
					markerObject = $('#'+selector);
				}else{
					markerObject = markerTemplate.find('.lib');
					markerObject.attr('id',selector);
				}
				markerObject.attr('data-label',destObj.name);
				// markerObject.find('.small-ctn .icon').addClass('lib-destination-marker '+category);
				markerObject.find('.small-ctn .icon').attr('class', 'icon lib-destination-marker '+category);
				if(ent){
					var pos = {x: ent.vg.position.x, y: ent.vg.position.y, radius: mapviewer.camera.position.radius};
					if(Boolean(isNew)){
						$('#hidden-div').append(markerObject);
					}
					markerLib= mapviewer.addPOI({selector: '#'+selector, floor: ent.vg.floor, position: pos, scale: 12, alignment: {x: 0, y: 1.0}});
				}else{
					console.log('Can\'t find place:'+mapId);
				}
				destinationLib = markerLib;
				displayLibWindow(markerObject,category);
				markerObject.bind(clickEvent,function(e){if(e.preventDefault){e.preventDefault()}});
			}
		}
	}

	function hookMapLibEvents(poiObj,selector){
		$('#'+selector+' .small-ctn .icon').unbind(mouseHoverEvent);
		$('#'+selector+' .small-ctn .icon').unbind(mouseOutEvent);
		$('#'+selector+' .small-ctn .icon').unbind(clickEvent);
		
		// mousehover event
		$('#'+selector+' .small-ctn .icon').bind(mouseHoverEvent,function(e){
			if(e.preventDefault){e.preventDefault();}
			var $target = $(e.target);
			var lib = $target.parents('.lib');
			var category = lib.data('cat');
			displayLibWindow(lib,category);
		});
		
		//mouseout event
		$('#'+selector+' .small-ctn .icon').bind(mouseOutEvent,function(e){
			if(e.preventDefault){e.preventDefault();}
			var $target = $(e.target);
			var lib = $target.parents('.lib');
			var category = lib.data('cat');
			hideLibWindow(lib,category);
		});
		
		//click event
		$('#'+selector+' .small-ctn .icon').bind(clickEvent,{poiObj:poiObj},function(e){
			if(e.preventDefault){e.preventDefault();}
			var poiObj = e.data.poiObj;
			var $target = $(e.target);
			var parent = $target.parents('.lib');
			var category = parent.data('cat');
			var position = parent.data('pos');
			var itemId = parent.data('id');
			var mapId = parent.data('map-id');
			mapApp.clickPoiController({category:category,position:position,itemId:itemId,mapId:mapId,map_type:'vg'});
		});
	}

	function removelibObj(objPOI,selector){
		objPOI.options('visible', false, false);
		//objPOI.remove();
	}

	this.clickPoiController = function(params){
		var category = params.category;
		var position = params.position;
		var itemId = params.itemId;
		var mapId = params.mapId;
		var map_type = params.map_type;
		if ($('.locations-panel').hasClass('start') || $('.locations-panel').hasClass('end')){
            mapApp.fillDirectionInputs({category:category,position:position,id:itemId,map_id:mapId,map_type:map_type});
        }else{
        	mapApp.showProfile({category:category,position:position,id:itemId,map_id:mapId,map_type:map_type});
        }
	}

	this.showProfile = function(params){
		var category = params.category;
		var position = params.position;
		var itemId = params.id;
		var mapId = params.map_id;
		var mapType = params.map_type;
		var cxtObj;
		var opParams={
			category:category,
			position:position,
			id:itemId,
			map_id:mapId,
			map_type:mapType
		};
		if(category === 'parking_planner'){
			cxtObj= mapApp.findParkingPlannerByPos({position:position});
			afterShowProfile(cxtObj,opParams);
		}else if(category === 'entrance_planner'){
			cxtObj= mapApp.getOutdoorEntranceDetails(mapId);
			afterShowProfile(cxtObj,opParams);
		}else if (mapType === 'vg'){
			cxtObj = findCxtDataByPos({category:category,id:itemId,position:position});
			afterShowProfile(cxtObj,opParams);
		}else{
			cxtObj= mapApp.findOutCxtDataByPos({category:category,id:itemId,position:position});
			afterShowProfile(cxtObj,opParams);
		}
	}

	function afterShowProfile(cxtObj,opParams){
		$('#search-filter').val(cxtObj.name);
		mapApp.clearRoute(false);
		mapApp.clearOriginPoint();
		mapApp.clearDestinationPoint();
		mapApp.addDestinationPoint({id:opParams.id,position:opParams.position,category:opParams.category,map_type:opParams.map_type,map_id:opParams.map_id});
		mapApp.fillInfoCard();
		if (opParams.map_type === 'vg'){
			mapApp.centerPoint(opParams.map_id).then(function(){
				mapApp.drawDestinationLib();
			});
		}else{
			mapApp.drawDestinationLibOutdoor(destObj);
			mapApp.setCenterGG({lat:destObj.lat,lng:destObj.lng});
		}
		$('.right-omnibox').removeClass('explore locations routing').addClass('navigation');
		mapApp.disableFloorNavPanel();
	}

	this.fillDirectionInputs = function(params){
		var category = params.category;
		var position = params.position;
		var itemId = params.id;
		var mapId = params.map_id;
		var mapType = params.map_type;
		var cxtObj;
		var opParams={
			category:category,
			position:position,
			id:itemId,
			map_id:mapId,
			map_type:mapType
		};
		if(category === 'parking_planner'){
			cxtObj= mapApp.findParkingPlannerByPos({position:position});
			afterFillDirectionInputs(cxtObj,opParams);
		}else if(category === 'entrance_planner'){
			cxtObj= mapApp.getOutdoorEntranceDetails(mapId);
			afterFillDirectionInputs(cxtObj,opParams);
		}else if (mapType === 'vg'){
			cxtObj = findCxtDataByPos({category:category,id:itemId,position:position});
			afterFillDirectionInputs(cxtObj,opParams);
		}else{
			cxtObj= mapApp.findOutCxtDataByPos({category:category,id:itemId,position:position});
			afterFillDirectionInputs(cxtObj,opParams);
		}
	}

	function afterFillDirectionInputs(cxtObj,opParams){
		$('.mode-transport-panel').addClass('hide');
		$('.transport-panel').addClass('hide').removeClass('outdoor-indoor indoor-outdoor outdoor-outdoor my-park my-exit');
		$('#search-filter').val(cxtObj.name);
		mapApp.clearRoute(false);
		if ($('.locations-panel').hasClass('start')){
			$('#start-point').val(cxtObj.name);
			mapApp.addOriginPoint({id:opParams.id,position:opParams.position,category:opParams.category,map_type:opParams.map_type,toAddress:0,map_id:opParams.map_id,cat_id:opParams.category_id});
			$('.right-omnibox').removeClass('navigation explore routing').addClass('locations');
			if (!destObj) {
				$('#end-point').focus();
			}
			mapApp.updatePanels();
		}else{
			if ($('.locations-panel').hasClass('end')){
				$('#end-point').val(cxtObj.name);
				mapApp.clearDestinationPoint();
				mapApp.addDestinationPoint({id:opParams.id,position:opParams.position,category:opParams.category,map_type:opParams.map_type,toAddress:0,map_id:opParams.map_id,cat_id:opParams.category_id});
				if(opParams.map_type === 'vg'){
					mapApp.centerPoint(opParams.map_id).then(function(){
						mapApp.drawDestinationLib();
					});
				}else{
					mapApp.drawDestinationLibOutdoor(destObj);
				}
				$('.right-omnibox').removeClass('navigation explore routing').addClass('locations');
				if (!originObj) {
					$('#start-point').focus();
				}
				mapApp.updatePanels();
			}
		}
	}

	this.updatePanels = function(){
		if($('#start-point').val().trim()!=='' && $('#end-point').val().trim()!==''){
			var routePoint = mapApp.getRouteWaypoint();
			var startP = mapApp.getOrigin();
			var destination = mapApp.getDestination();
			var destAddress = destination.buildingAddress[destination.toAddress];
			var modeTranspBtn = $('.btn-transport-mode.active');
			var modeTrans = modeTranspBtn.data('action');
			var modePanel = modeTranspBtn.data('panel');
			var classMode = mapApp.getRouteDirection();

			$('.locations-panel').removeClass('start end parking').addClass('full');
			$('.mode-transport-panel').addClass('hide');
			$('.transport-panel').addClass('hide').removeClass('outdoor-indoor indoor-outdoor outdoor-outdoor my-park my-exit');

			if(classMode === 'outdoor-outdoor'){
				$('.mode-transport-panel').removeClass('hide');
				$('.'+modePanel).addClass('hide');
			}else if(classMode === 'indoor-outdoor' || classMode === 'outdoor-indoor'){
				if(modeTrans==='driving'){
					var parkingListCat = $('#parking-options-input').val();
					var defaultEntrance = CONFIG['DEFAULT_ENTRANCE_MAPID'];
					var defaultPoint = (parkingListCat==='parking')?mapApp.getPreferedParking():mapApp.getOutdoorEntranceDetails(defaultEntrance);
					if(classMode === 'outdoor-indoor' && parkingListCat==='parking' && destAddress.gar_map_id!==''){
						defaultPoint = mapApp.getPreferedParkingByMapId(destAddress.gar_map_id);
					}
					
					$('#main-garage').text(defaultPoint.name);
					$('#main-garage').attr('data-cat',parkingListCat);
					$('#main-garage').attr('data-position',defaultPoint.position);
				}
				$('.mode-transport-panel').removeClass('hide');
				$('.'+modePanel).addClass(classMode);
				$('.'+modePanel).removeClass('hide');
			}
		}
	}
	
	this.centerPoint= function(mapId){
		deferredCenterPoint = jQuery.Deferred();
		var result = deferredCenterPoint.promise();
		var currentState = multiBuildingView.getCurrentExploreState();
		//if(currentState.mode === 'floor'){
			var ent = mapviewer.getPlace(mapId);
			if (ent !== false) {
				var pos = {x: ent.vg.position.x, y: ent.vg.position.y, radius: centerCameraRadius};
				var gotoEntityFunction = function() { 
					var goToParameters = {
	                    mode: 'floor',//global
	                    floorID:ent.vg.floor,
	                    viewpoint: {
	                        position: pos,
	                        pitch: defaultFloorCameraPitch
	                     }
	                };
	                multiBuildingView.goTo(goToParameters).done(function(){
	                	//currentFloor={target:'floor',index:ent.vg.floor};
	                	deferredCenterPoint.resolve();
	                });
				};
				
				if (mapviewer.getCurrentFloor() == ent.vg.floor) {
					gotoEntityFunction();
				} else {
					multiBuildingView.goTo({
			            mode: 'floor',
			            floorID: ent.vg.floor,
			            animationDuration: 0
			        }).done(gotoEntityFunction);
					//multiFloorApp.changeFloorOverride(ent.vg.floor, {animationDuration: 500}).then(gotoEntityFunction);
				}
			}else{
				console.log('Can\'t center position '+mapId);
			}
		//}
		return result;
	}

	this.generateLink = function (params) {
		params['route_direction'] == params['route_direction'] || '“indoor-indoor';
		params['handicap_accessible'] = params['handicap_accessible'] || true;
		params['pdf_mode'] = params['pdf_mode'] || "map";
		params["lang"] = params["lang"] || "en";

		if (!params["email"] && !params["phone"]) params['print'] = true;

		if (
			((!params['source_map_id'] || !params['dest_map_id']) && (!params['source_coordinate'] || !params['dest_coordinate'])) ||
			(params["route_direction"] != "indoor-indoor" && params["route_direction"] != "indoor-outdoor" && params["route_direction"] != "outdoor-indoor" && params["route_direction"] != "outdoor-outdoor") ||
			(params["route_direction"] == "outdoor-indoor" && !params["source_coordinate"]) ||
			(params["route_direction"] == "indoor-outdoor" && !params["dest_coordinate"]) ||
			(params["route_direction"] == "indoor-indoor" && (params["dest_coordinate"] || params["source_coordinate"])) ||
			(params['pdf_mode'] != "map" && params['pdf_mode'] != "text")
		) throw "Incorrect params!";

		var queryString = "?",
			keys = Object.keys(params);
		keys.forEach(function (key, i) {
			queryString += key + '=' + params[key] + (i < keys.length - 1 ? '&' : '');
		});

		return queryString;
	}

	this.sendDirections = function (data) {
		var self = this;
		return new Promise(function (resolve, reject) {
			try {
				data = data || {
					action: 'PRINT'
				};
				data.action = data["action"] || "PRINT";
				data.pdf_mode = data["pdf_mode"] || "map";
				data.handicap_accessible = data["handicap_accessible"] || true;

				var params = {};
				params.pdf_mode = data.pdf_mode;

				if (data.action != 'PRINT' && data.action != 'SEND_TO_EMAIL' && data.action != 'SEND_TO_PHONE') throw "Invalid parameters!";
				else {
					if (data.action == 'PRINT') params.print = true;
					else if (data.action == 'SEND_TO_EMAIL') params.email = data.email;
					else if (data.action == 'SEND_TO_PHONE') params.phone = data.phone;
				}

				var startType = currentNavigation.getRouteStages()[0].map_type;
				var endType = currentNavigation.getRouteStages()[currentNavigation.getRouteStages().length - 1].map_type;

				params.route_direction = startType + "-" + endType;
				params.lang = lang == "en_us" ? "en" : "es";

				if (startType == 'indoor' && endType == 'indoor') {
					params.source_map_id = stages[0].start.map_id;
					params.dest_map_id = stages[0].end.map_id;
				} else if (startType == 'indoor' && endType == 'outdoor') {
					params.source_map_id = stages[0].start.map_id;
					params.dest_map_id = stages[0].end.map_id;

					params.source_coordinate = (stages[1].start.lat + "," + stages[1].start.lng);
					params.dest_coordinate = (stages[1].end.lat + "," + stages[1].end.lng);
				} else if (startType == 'outdoor' && endType == 'indoor') {
					params.source_map_id = stages[1].start.map_id;
					params.dest_map_id = stages[1].end.map_id;

					params.source_coordinate = (stages[0].start.lat + "," + stages[0].start.lng);
					params.dest_coordinate = (stages[0].end.lat + "," + stages[0].end.lng);
				} else if (startType == 'outdoor' && endType == 'outdoor') {
					params.source_coordinate = (stages[0].start.lat + "," + stages[0].start.lng);
					params.dest_coordinate = (stages[0].end.lat + "," + stages[0].end.lng);
				}


				resolve(params);
			} catch (err) {
				reject(err);
			}

		});
	}

	this.shareDirections = function(action,mode){
		var newwindow;
		if(action ==='phone'){
			$('#send-phone-mode').val(mode);
			return;
		}
		$('body').addClass('loading');
		if(isSafari){
			newwindow = window.open('','',"width=500, height=500");
        	newwindow.blur();
		}
		_this.generatePDF(mode).then(function(sharePDFObj){
			sharePDFObj.printPDF(newwindow);
			$('body').removeClass('loading');
		});
	}

	this.generatePDF = function(mode){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		currentNavigation.getRouteImages().then(function(images){
			var instructions =currentNavigation.getRouteStages(); 
			var shortStartAddress = generateShortAddress(originObj,true);
			var shortDestAddress = generateShortAddress(destObj,true);
			var startAddress = (originObj.category==='outdoor')?originObj.name+', '+originObj.buildingAddress[0]['building_address']:(shortStartAddress!=='')?originObj.name+', '+shortStartAddress:originObj.name;
			var destAddress = (destObj.category==='outdoor')?destObj.name+', '+destObj.buildingAddress[0]['building_address']:(shortDestAddress!=='')?destObj.name+', '+shortDestAddress:destObj.name;
			var pdf = new SharePDF({translator:translator});
			pdf.createPDF({mode:mode,instructions:instructions,images:images,origin:startAddress,destination:destAddress});
			deferred.resolve(pdf);
		});
		return result;
	}

	function pushOverlayPoi(params){
		if(!overlaysPOI[params.category]){
			overlaysPOI[params.category] = {};
		}
		overlaysPOI[params.category][params.selector]=params.obj;
	}

	function generateAddress(obj){
		var address= '';
		if(obj.building_name){
			address += obj.building_name;
		}
		if(obj.floor){
			address += (address==='')?obj.floor:','+obj.floor;
		}
		if(obj.wing_name){
			address += (address==='')?obj.wing_name:'<br>'+obj.wing_name+' Wing';
		}
		if(obj.branch){
			address += (address==='')?obj.branch:'<br>'+obj.branch;
		}
		if(obj.building_address){
			address += (address==='')?obj.building_address:'<br>'+obj.building_address;
		}
		if(obj.phone){
			address += (address==='')?obj.phone:'<br>Phone: '+obj.phone;
		}
		if(obj.email){
			address += (address==='')?obj.email:'<br>Email: <a class="email-address" href="mailto:'+obj.email+'">'+obj.email+'</a>';
		}
		if(obj.website){
			address += (address==='')?obj.website:'<br>Website: <a class="website" href="'+obj.website+'" target="_blank">'+obj.website+'</a>';
		}
		if(obj.hours_of_operation){
			address += (address==='')?'Hours: '+obj.hours_of_operation:'<br>Hours: '+obj.hours_of_operation;
		}
		return address;
	}

	function generateShortAddress(obj,forPDF){
		var separator;
		var address= '';
		var tempObj = obj;
		if(obj.buildingAddress.length > 0){
			var ba = obj.buildingAddress[obj.toAddress];
			if(ba.floor){
				address += ba.floor;
			}
			if(ba.prime && ba.building_name){
				var tempName = (ba.building_name)?ba.building_name:ba.building_address;
				separator = (forPDF)?', ':'<br>';
				address += (address==='')?tempName:separator+tempName;
			}else if(!ba.prime && ba.building_address){
				separator = (forPDF)?', ':'<br>';
				address += (address==='')?ba.building_address:separator+ba.building_address;
			}
			if(ba.branch){
				address += (address==='')?ba.branch:', '+ba.branch;
			}
		}
		return address;
	}

	function pushDataToCard(obj,category){
		var ph = obj;
		var dataAddress;
		var name = ph.name;
		var specialty= (ph.specialty)?ph.specialty:'';
		var description= (ph.description)?ph.description:'';
		var imageUrl= (ph.imageUrl)?baseUrl+'../'+ph.imageUrl:'./images/'+CONFIG["IMAGE_URL"];
		var profileImageUrl= (ph.profileImageUrl)?baseUrl+'/'+ph.profileImageUrl:'./images/'+CONFIG["PROFILE_IMAGE_URL"];
		var websiteUrl = (ph.websiteUrl)?ph.websiteUrl:'';

		if(category === 'staff'){
			$('.profile-data-card').addClass('profile');
			profileImageUrl = imageUrl;
			imageUrl = './images/'+CONFIG["IMAGE_URL"];
		}else{
			$('.profile-data-card').removeClass('profile');
		}
		if(description === '' && websiteUrl === ''){
			$('.profile-data-card').addClass('hide');
		}else{
			$('.profile-data-card').removeClass('hide');
		}
		$('#main-card-name').html(name);
		$('#card-fullname').html(name);
		$('#card-specialty').html(specialty);
		$('#profile-pic').css('background', 'transparent url(' + profileImageUrl +') no-repeat center/contain');
		$('.card-background').css('background', 'white url("'+imageUrl+'") no-repeat center/cover');
		

		$('#card-profile').html(description);
		$('#card-profile-website').html('<span>Website: <a class="website" href="'+websiteUrl+'" target="_blank">'+websiteUrl+'</a></span>');
		$('.address-data-card .list-group').html('');
		for(var i=0; i< ph.buildingAddress.length; i++){
			var p = ph.buildingAddress[i];
			dataAddress = fillInfoCardAddress({
				building_name: p.building_name,
				floor:p.floor,
				wing_name:p.wing_name,
				branch:p.branch,
				building_address:p.building_address,
				phone:p.phone,
				email:ph.email,
				website:p.website,
				hours_of_operation:(p.hours_of_operation)?p.hours_of_operation:'',
				tabName:(p.prime)?(p.branch)?p.branch:translator.getLangLabel('PAGE_TITLE'):(p.building_address)?p.building_address:name
			},i);
			$('.address-data-card .list-group').append(dataAddress);
		}
	}

	function fillInfoCardAddress(pObj,pAddressIndex){
		var lictn = $('<div>').html($('#address-group-template').html());
		var address= '';
		address = generateAddress(pObj);
		var tabName = pObj.tabName;
		var $buildingName = lictn.find('.card-building');
		var $cardAddress = lictn.find('.card-address');
		$buildingName.html(tabName);
		$cardAddress.html(address);
		lictn.find('.goto-card-btn').attr('data-index',pAddressIndex);
		if(pAddressIndex > 0){
			lictn.find('.address-group').addClass('closed');
		}
		return lictn.html();
	}

	function findCxtDataByPos(params){
		var collection = cxtData[params.category];
		var data = collection[params.position];
		return data;
	}

	this.findCxtDataByPosCategory = function(params){
		var collection = cxtData[params.category];
		var data = collection[params.position];
		return data;
	}

	this.getMapIdsToData = function(){
		return mapIdsToData;
	}

	this.findOutCxtDataById = function(params){
		var collection = outCxtData[params.category];
		for (var index in collection){
			if(collection[index].id == params.id){
				return collection[index];
			}
		}
	}

	this.findOutCxtDataByPos = function(params){
		var collection = outCxtData[params.category];
		var data = collection[params.position];
		return data;
	}

    this.requestLoadVGMap = function(params){
    	if(params.process){
    		pendingVGTasks.push({context:params.context,process:params.process,args:params.args});
    	}
    	loadVGMap();
    }

	function loadVGMap(params){
		if(vgMapStatus === 'UNLOAD'){
			vgMapStatus = 'LOADING';
			if (isWebGLSupported())
			{
				mapviewer = new vg.mapviewer.Mapviewer();

			var mapviewer_parameters = {
				logoPosition: 'TOP_RIGHT',
				path: mapURL,
				initialFloorName: initialFloorName
				//rendererType: 'webgl',
				// Uncomment these to have the status bar on mouseover
				//onObjectMouseOver: onObjectMouseOver,
				//onObjectMouseOut: onObjectMouseOut,
				//onObjectMouseUp: onObjectMouseUp
			};

				
				setVGMapContainerSize();
				mapviewer.initialize($('#'+vgMapContainerId)[0], mapviewer_parameters)
				.then(function() {
					vgMapStatus = 'LOADED';
					console.log('map loaded');
					setTimeout(function() {
						var pos = mapviewer.camera.position;
						pos.radius = pos.radius/0.55;
						mapviewer.camera.position = pos;
					}, 0)
					if(pendingVGTasks.length){
						onVgMapLoadCompleted();
					}
		        })
				.catch(function(result)
				{
		            var message = 'Unknown map initialize error';
					if (typeof(result)!=='undefined' && typeof(result.message)!=='undefined')
					{
						message = 'Map initialize error: ' + result.message;
					}
					alert(message);
				}) // chaining
				// .tap(function()
				// {
				// 	if(pendingVGTasks.length){
				// 		$('body').addClass('loading');
				// 	}
				// }); 
			}
			else
			{
				alert("Your browser does not have WebGL support, update or try another browser, no map will be loaded or displayed");
			}
		}else if(vgMapStatus === 'LOADED'){
			onVgMapLoadCompleted();
		}else if(vgMapStatus === 'LOADING'){
			$('body').addClass('loading');
		}
	}

	function onVgMapLoadCompleted(){
		/*var initialPosition = {
			position: kPos,
			pitch: defaultKioskPitch,
			floorID: kiosk.vg.floor,
			heading: kioskPosition.orientation
		}*/

		if(vgMapStatus!= 'LOADED'){
			return;
		}
		vgMapStatus = 'STARTED';
		$('body').addClass('loading');
		 //Add Map labels
		 if (enable_map_labels){addMapLabels()} //CORE-1277

		// Start the rendering of the map.
		mapviewer.start();

		$(window).resize(function(event) {
			setResizeVGMap();
		});

		if (typeof(VgMultiBuildingView) !== 'undefined' &&  typeof(VgMultiBuildingView.setupMultiBuilding) == "function")
        {
            VgMultiBuildingView.setupMultiBuilding(mapviewer,{floors:_this.getFloors(),buildings:_this.getBuildings(),initialPosition:null}).then(function(){
        		var initialState = multiBuildingView.getCurrentExploreState();
        		mapView = (initialState.mode === 'floor')?'site':initialState.mode;
        		if(pendingVGTasks.length){
					for(var i=0;i<pendingVGTasks.length;i++){
						var p = pendingVGTasks[i];
						var process = p.process;
						var context = p.context;
						process.apply(context,p.args);
					}
					pendingVGTasks= [];
				}
            });
        }

				mapviewer.on('VgMultiBuildingView.exploreStateChanged',function(event){
					var view = event.args.view;
					var current = event.args.current;
					if (current.mode == "floor" ||"building"){
						showPoisLibs({floorID:current.mode==='global'?current.mode:current.floorID});
						mapApp.drawDestinationLib();
					}
				});

		mapviewer.on('VgMultiBuildingView.exploreStateWillChange',function(event) {
	        var view = event.args.view;
	        var target = event.args.target;
	        hidePoisLibs();
	        removeDestinationLib();
	        isRouting = $('body').hasClass('routing');
	        if (isRouting){
	        	hideBuildingLabels(view);
	        }
        });

		mapviewer.on('MyNavigation.lastInstruction',function(ev) {
			if(destObj.mapType==='vg' && destObj.buildingAddress.length>0){
				var indoorAddress = destObj.buildingAddress[destObj.toAddress];
				if(indoorAddress.prime){
					//addYouHaveArriveLib();
				}
			}
		});

		mapviewer.on('MyNavigation.lastStage',function(ev) {
			if(destObj.mapType==='vg' && destObj.buildingAddress.length>0){
				var indoorAddress = destObj.buildingAddress[destObj.toAddress];
				if(indoorAddress.prime){
					//addYouHaveArriveLib();
				}
			}
		});

		mapviewer.on('MyNavigation.stageComplete',function(ev) {
			_this.stageCompleted();
		});

		mapviewer.on('MyNavigation.instructionComplete',function(ev) {
			_this.instructionCompleted();
		});

		setResizeVGMap();
		$('body').removeClass('loading gg-map').addClass('vg-map');
	}

	function addMapLabels(){
		var places = mapviewer.getPlaces();
		if(mapIdsToData && cxtData){
			for (var placename in places)
			{
				var itemId = mapIdsToData[placename];
				if(itemId){
					var cxtObj = cxtData[itemId.category][itemId.position];
					if(cxtObj){mapviewer.setPlaceName(placename, cxtObj.name)};
				}
			}
		}
	}

	function hideBuildingLabels(view){
		for (i in view.activeBuildingMarkerPOIs){
            view.activeBuildingMarkerPOIs[i].hide();
            view.inactiveBuildingMarkerPOIs[i].hide();
	    }
	}

	this.stageCompleted =function(){
		if (typeof(currentNavigation) == 'object' && currentNavigation !== null && !$(this).hasClass('disabled')) {
			var currentStage = currentNavigation.getCurrentStage();
			var mapType = currentStage.map_type;
			checkMapView(mapType);
		}
	}

	this.instructionCompleted =function(){
		if (typeof(currentNavigation) == 'object' && currentNavigation !== null && !$(this).hasClass('disabled')) {
			var currentInst = currentNavigation.getCurrentInstruction();
			var mapType = (currentInst.type === 'vg')?'indoor':'outdoor';
			checkMapView(mapType);
		}
	}

	this.drawShuttleRoute = function(routeId){
		googleController.drawShuttleRoute(routeId);
	}

	this.removeShuttleRoute = function(){
		googleController.removeShuttleRoute();
	}

	function checkMapView(mapType){
		var cmapView = (mapType==='outdoor')?mapType:'site';
		mapView = cmapView;
		if(cmapView==='site'){
			$('body').addClass('vg-map').removeClass('gg-map');
		}else{
			$('body').addClass('gg-map').removeClass('vg-map');
		}
	}

	function setResizeVGMap(){
		setVGMapContainerSize();
		mapviewer.resize($('#'+vgMapContainerId).width(), $('#'+vgMapContainerId).height());
	}

	// We could use isWebGLSupported to know if we can use VisioKiosk.
	function isWebGLSupported ()
	{
		var result = false;
		if (window.WebGLRenderingContext)
		{
			var cvsEl, ctx;
		    cvsEl = document.createElement("canvas");
		    ctx = cvsEl.getContext("webgl") || cvsEl.getContext("experimental-webgl");

		    if (ctx) {
		    	result = true;
		        // This makes sure the Browser supports WebGL and that it can create the WebGL context
		    }
		}
		return result;
	}

	function setVGMapContainerSize(){
		 //set map container before rendering map
	    var h = $(window).height();
		var w = $(window).width();
			
	    $('#'+vgMapContainerId).height(h);
	    $('#'+vgMapContainerId).width(w);
	}

	function loadConnexientData(){
		var dbDataFilesArray = translator.getDBDataFiles();

		//var extension = dbDataFilesArray[1];
	    var filesKeys = Object.keys(dbDataFilesArray);
		totalDBFiles = filesKeys.length;
		counterDBFiles = 0;



		for(var i=0; i< totalDBFiles; i++){
			var fileKey = filesKeys[i];
			processJsonDataFiles({key:fileKey});
		}
	}


	function processJsonDataFiles(pParams){
		var fileKey = pParams.key;
		switch(fileKey){
			case 'settings':
			loadAppSettings(pParams).then(function(data){
				settings = data;
				_this.dispatch("MapSDK.settingsLoaded");
				directionsUseDistance = (settings[0]['directions_use_time']=== '1')?false:true;
				enableLandmarks = (settings[0]['enable_landmarks']=== '1')?true:false;
				checkDBDataCompleted(pParams);
			});
			break;
			case 'floors':
			loadFloors().then(function(data){
				floors = data;
				checkDBDataCompleted(pParams);
			});
			break;
			case 'buildings':
			loadBuildings().then(function(data){
				buildings = data;
				checkDBDataCompleted(pParams);
			});
			break;
			case 'classifications':
			loadExtraData(pParams).then(function(data){
				classifications = data;
				checkDBDataCompleted(pParams);
			});
			break;
			case 'institutions':
			loadExtraData(pParams).then(function(data){
				institutions = data;
				checkDBDataCompleted(pParams);
			}); 
			break;
			case 'sites':
			loadExtraData(pParams).then(function(data){
				sites = data;
				checkDBDataCompleted(pParams);
			}); 
			break;
			case 'waypoints':
			loadWaypointsData().then(function(data){
				waypoints = data;
				checkDBDataCompleted(pParams);
			});
			break;
			case 'parking_planner':
			loadParkingPlannerData(pParams).then(function(data){
				pplanners = data;
				checkDBDataCompleted(pParams);
			});
			break;
			case 'time_exclusions':
			loadTimeExclusions().then(function(data){
				time_exclusions = data;
				checkDBDataCompleted(pParams);
			});
			break;
			case 'outside_locations':
			loadOutPOIData(pParams).then(function(data){
				checkDBDataCompleted(pParams);
			});
			break;
			case 'physicians':
			loadPhysicians(pParams).then(function(data){
				checkDBDataCompleted(pParams);
			});
			break;
			default:
			loadIndoorPOIData(pParams).then(function(data){
				checkDBDataCompleted(pParams);
			});
			break;
		}
	}

	function checkDBDataCompleted(pParams){
		counterDBFiles += 1;
		if(totalDBFiles === counterDBFiles){
			_this.dispatch("MapSDK.jsonDataLoaded"); 
			console.log('dta loaded. Load json');
			console.log(outCxtData);
			console.log(cxtData);
			loadVGMap();
		}
	}

	function loadAppSettings(){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var url = translator.getFileUrl('settings');
		$.getJSON(url, function(data) {
			var settingsData = data.results;
			deferred.resolve(settingsData);
		});
		return result;
	}

	function loadTimeExclusions(pParams) {
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var rms = new Array();
		var url = translator.getFileUrl('time_exclusions');
		$.getJSON(url, function(data) {
			var data = data.results;
			$.each(data, function(i, row) {
				rms.push({
					id: row.id, 
					hospital_hotspot_id: row.hospital_hotspot_id,
					waypoint_id: row.waypoint_id,
					day_of_week: row.day_of_week,
					open_time: row.open_time,
					close_time: row.close_time,
					close_time_message: row.close_time_message
				});
			});
			deferred.resolve(rms);
		});
		return result;
	}

	function loadFloors(){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var rms = new Array();
		var url = translator.getFileUrl('floors');
		$.getJSON(url, function(data) {
			var floorData = data.results;
			$.each(floorData, function(i, row) {
				rms.push({id: row.id, name: row.name,map_floor:row.floor_layer,abbreviation:row.abbreviation,building_id:row.hospital_building_id,vg_floor_id:row.vg_floor_id});
			});
			deferred.resolve(rms);
		});
		return result;
	}

	function loadBuildings(){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var rms = new Array();
		var url = translator.getFileUrl('buildings');
		$.getJSON(url, function(data) {
			var bData = data.results;
			$.each(bData, function(i, row) {
				rms.push({id: row.id, name: row.name,building_map_id:row.building_map_id,vg_building_id:row.vg_building_id});
			});
			deferred.resolve(rms);
		});
		return result;
	}

	function loadWaypointsData(){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var rms = {};
		var url = translator.getFileUrl('waypoints');
		$.getJSON(url, function(data) {
			var data = data.results;
			$.each(data, function(i, row) {
				if(row.map_id!==''){	
					if(!rms[row.map_id]){
						rms[row.map_id] =[];
					}	
					rms[row.map_id].push({id: row.id, building_address: row.address, map_id:row.map_id, lat:Number(row.latitude),lng:Number(row.longitude)});
				}
			});
			deferred.resolve(rms);
		});
		return result;
	}

	//TODO:Temporary fix to simulate indoor-outdoor
	function loadParkingPlannerData(pParams){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var rms = {};
		var url = translator.getFileUrl('parking_planner');

		$.getJSON(url, function(data) {
			var pData = data.results;
			var index =0;
			var catCounter = {};
			$.each(pData, function(i, row) {
				if(row.map_id!==''){
					rms[index] = {
						id: row.id,
						position:index,
						map_id:row.map_id,
						name: row.name,
						building_address:row.address,
						lat:Number(row.latitude),
						lng:Number(row.longitude),
						is_prefered:row.is_prefered
						// hotspot_type_id: 7,
						// floor_id: "outside",
						// building_id: "outside"
					};

					// processCategoryDataRow(rms[index], cxtData, true, catCounter);
					processCategoryDataRow(rms[index], outCxtData, false, catCounter);

					index++;
				}
			});

			deferred.resolve(rms);
		});
		return result;
	}

	function loadPhysicians(pParams){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var url = translator.getFileUrl(pParams.key);
		var catCounter = {};
		var catCounterOutdoor = {};
		$.getJSON(url, function(data) {
			var array = data.results;
			var items = array.concat();
			setTimeout(function(){
				var total = Math.min(items.length, CONFIG['CHUNK_SIZE']);
				for(var i=0;i<total;i++){
					var item = items.shift();
					var isIndoor = (item['addresses'] && item['addresses'][0] && item['addresses'][0].prime === '0')?false:true;
		        	//cxtData contains outdoor and indoor physicians (in the directory panel it shows both indoor and outdoor physicians)
		        	processCategoryDataRow(item,cxtData,isIndoor,catCounter);
		        	if (!isIndoor){
		        		//outCxtData only have outdoor physicians
		        		processCategoryDataRow(item,outCxtData,isIndoor,catCounterOutdoor);
		        	}
				}
		        if (items.length > 0){
		            setTimeout(arguments.callee, 100);
		        }else{
		        	deferred.resolve();
		        }
		    }, 100);
		});
		return result;
	}

	function loadExtraData(pParams){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var rms = {};
		var url = translator.getFileUrl(pParams.key);
		$.getJSON(url, function(data) {
			var data = data.results;
			$.each(data, function(i, row) {
				var item = 	processSimpleDataRow(row);
				rms[item.id] = item;//{id: row.id, name: row.name, map_id: row.map_id,building_address:row.building_street_address};
			});
			deferred.resolve(rms);
		});	

		return result;	
	}

	function processSimpleDataRow(row,isIndoor){
		var item = {};

		var building_address = [];
		var id = (row.id)?row.id:-1;
		var hotspot_name = (row.hotspot_name)?row.hotspot_name:'';
		var name = (row.name)?row.name:(row.location_title)?row.location_title:(row.room_map_id)?row.room_map_id:(hotspot_name!='')?hotspot_name:(row.first_name)?row.last_name+', '+row.first_name:'';
		var map_id = (row.map_id)?row.map_id:(row.room_no)?row.room_no:(row.hotspot_map)?row.hotspot_map:'';
		var building_street_address = (row.building_street_address)?row.building_street_address:(row.address)?row.address:'';
		var building_name = (row.building_name)?row.building_name:'';
		var acronym = (row.acronym)?row.acronym:'';
		var image_url =  (row.image_url)?row.image_url:'';
		var type = (row.hotspot_type_id)?row.hotspot_type_id:0;
		var lat = (row.lat)?Number(row.lat):0;
		var lng = (row.long)?Number(row.long):(row.lng ? Number(row.lng) : 0);
		var branch = (row.branch_name)?row.branch_name:'';
		var floor_name = (row.floor_name)?row.floor_name:'';
		var floor_number = (row.floor_layer)?row.floor_layer:'';
		var description = (row.description)?row.description:'';
		var phone = (row.phone && row.phone!=='')?row.phone:(row.extra1!=='')?row.extra1:'';
		var specialty = (row.specialty!=='')?row.specialty:'';
		var organization_id=(row.organization_id)?row.organization_id:'';
		var classification_id=(row.classification_id)?row.classification_id:'';
		var building_id=(row.building_id)?row.building_id:'';
		var site=(row.site)?row.site:'';
		var email=(row.email_id)?row.email_id:'';
		var wing_name=(row.wing_name)?row.wing_name:'';
        var website = (row.website)?formatURL(row.website):'';
		var profile_image_url =  (row.profile_image_url)?row.profile_image_url:'';
		var website_url = (row.website_url)?row.website_url: '';
		var hours_of_operation = (row.hours_of_operation)?row.hours_of_operation:'';
		var vg_building_id=(row.vg_building_id)?row.vg_building_id:(row.building_id ? row.building_id : '');
		var vg_floor_id=(row.vg_floor_id)?row.vg_floor_id:(row.floor_id ? row.floor_id : '');
		var tags = (row.tags)?row.tags.split(','):[];
		var gar_map_id = (row.gar_map_id)?row.gar_map_id:'';
		var ent_map_id = (row.ent_map_id) ? row.ent_map_id : '';
		var pavilion = (row.pavilion) ? row.pavilion : '';

		//When address is an array one instance have multiple address
		if(row.addresses && row.addresses instanceof Array){
			if(row.addresses.length === 0){
				return null;
			}
			for(var i=0; i<row.addresses.length;i++){
				var tempAddress = {};
				var address = row.addresses[i];
				var isPrime = (address.prime === '1' || address.prime === true);
				var isCampus = (address.is_campus === '1' || address.is_campus === true);
				tempAddress ={
					id:address.id,
					prime:isPrime,
					campus:isCampus,
					lat:(address.latitude)?Number(address.latitude):0,
					lng:(address.longitude)?Number(address.longitude):0,
					building_address: (address.address)?address.address.replace('/\n|\r\n|\r/g',' '):'',
					phone:(address.phone!=='')?address.phone:'',
					map_id:(address.map_id)?address.map_id:'',
					building_name:(address.building_name)?address.building_name:'',
					building_map_id:(address.building_map_id)?address.building_map_id:'',
					building_id:(address.building_id)?address.building_id:'',
					vg_building_id:(address.vg_building_id)?address.vg_building_id:'',
					floor:(address.floor_name)?address.floor_name:'',
					floor_number:(address.floor_layer)?address.floor_layer:'',
					vg_floor_id:(address.vg_floor_id)?address.vg_floor_id:(address.floor_id ? mapApp.getFloorByCnxID(address.floor_id).vg_floor_id : ''),
					branch:(address.branch_name)?address.branch_name:'',
					site:(address.site)?address.site:'',
					wing_name:(address.wing_name)?address.wing_name:'',
					website:(address.website)?formatURL(address.website):'',
					hours_of_operation:(address.hours_of_operation)?address.hours_of_operation:'',
					gar_map_id:(address.gar_map_id)?address.gar_map_id:'',
					ent_map_id:(address.ent_map_id)?address.ent_map_id:'',
					pavilion : (row.pavilion)?row.pavilion:'',
				}
				tempAddress.building_address = tempAddress.building_address.replace(/(\r\n|\n|\r)/gm,' ');
				if(isPrime){
					building_address.unshift(tempAddress);
				}else{
					building_address.push(tempAddress);
				}
			}
		}else{//Al elements from VG are going to have address as an array
			building_address.push({
				id:-1,
				//prime:true,
				prime:(isIndoor)?true:false,
				campus:true,
				lat:lat,
				lng:lng,
				building_address:building_street_address,
				phone:phone,
				map_id:map_id,
				building_name:building_name,
				//building_map_id:(address.building_map_id)?address.building_map_id:'',
				building_id:building_id,
				vg_building_id:vg_building_id,
				vg_floor_id:vg_floor_id,
				floor:floor_name,
				floor_number:floor_number,
				branch:branch,
				site:site,
				wing_name:wing_name,
				website:website,
				hours_of_operation:hours_of_operation,
				gar_map_id:gar_map_id,
				ent_map_id:ent_map_id,
				pavilion:pavilion,
			});
		}

		if (pavilion) {
			name += (' - ' + pavilion);

			if (floor_name) {
				name += (' - ' + floor_name);
			}
		} else {
			if (building_name) {
				name += (' - ' + building_name);
			}

			if (floor_name) {
				name += (' - ' + floor_name);
			}
		}

		item = {id: id,
			name: name,
		 	map_id: map_id,
			building_address:building_address,
			building_id: building_id,
			vg_floor_id: vg_floor_id,
		  	acronym:acronym,
		  	image_url: image_url,
		  	type:type,
			description: description,
			phone:phone,
			specialty:specialty,
			organization_id:organization_id,
			classification_id:classification_id,
			email:email,
			website:website,
			map_type:'vg',
			profile_image_url: profile_image_url,
			website_url:website_url,
			tags: tags,
			lat: lat,
			lng: lng,
			pavilion:pavilion
		};
		return item;
	}

	function loadIndoorPOIData(pParams){
		var catCounter = {};
		return processJsonDataList(pParams,cxtData,true);
	}

	function loadOutPOIData(pParams){
		var catCounter = {};
		return processJsonDataList(pParams,outCxtData,false);
	}

	
	/*
	* Process chunks from the json file to prevent browser crash for long data files 100ms
	*/
	function processJsonDataList(pParams,cxtData,isIndoor){
		var deferred = jQuery.Deferred();
		var result = deferred.promise();
		var url = translator.getFileUrl(pParams.key);
		var catCounter = {};
		$.getJSON(url, function(data) {
			var array = data.results;
			var items = array.concat();
			setTimeout(function(){
				var total = Math.min(items.length, CONFIG['CHUNK_SIZE']);
				for(var i=0;i<total;i++){
					var item = items.shift();
		        	processCategoryDataRow(item,cxtData,isIndoor,catCounter);
				}

		        if (items.length > 0){
		            setTimeout(arguments.callee, 100);
		        }else{
		        	deferred.resolve();
		        }
		    }, 100);
		});
		return result;
	}

	/*
	* Process each file in chunks to prevent one long file process more than 100ms
	*/
	function processCategoryDataRow(row,cxtData,isIndoor,catCounter){
		var item = processSimpleDataRow(row,isIndoor);
		if(item !=null){
			var objCat = (isIndoor)?_this.getCategoryById(item.type):_this.getOutCategoryById(item.type);
			if(objCat){
				var catName = (row.room_map_id)?'restroom':(row.first_name)?'staff':objCat.name;
				if(!cxtData[catName]){
					cxtData[catName] = {};
				}
				if(!catCounter[catName]){
					catCounter[catName] = 0;
				}

				var position = catCounter[catName];
				item['position'] = position;
				item['category'] = catName;
				item['map_type'] = (isIndoor)?'vg':'gg';

				cxtData[catName][item.position] = item;
				if(isIndoor){
					mapIdsToData[item.map_id] = {id: item.id,category:catName,position:item.position};
					vgIds['labels'][item.map_id]=[item.map_id,item.name];

					var itemFloorNumber = item.vg_floor_id || item.floor_id;
					itemFloorNumber = (item.building_address[0]['prime'])?item.building_address[0]['vg_floor_id']:itemFloorNumber;
					if(!floorToPois[itemFloorNumber]){
						floorToPois[itemFloorNumber] = [];
					}

					floorToPois[itemFloorNumber].push({position:item.position,category:catName});
				}

				allCxtData.push(item);
				catCounter[catName] = position+ 1;
			}
		}
	}

};
mapSDK.prototype = new Dispatcher();

function Dispatcher(){
	this.events=[];
}
Dispatcher.prototype.addEventlistener=function(event,callback){
	this.events[event] = this.events[event] || [];
	if ( this.events[event] ) {
		this.events[event].push(callback);
	}
}
Dispatcher.prototype.removeEventlistener=function(event,callback){
	if ( this.events[event] ) {
		var listeners = this.events[event];
		for ( var i = listeners.length-1; i>=0; --i ){
			if ( listeners[i] === callback ) {
				listeners.splice( i, 1 );
				return true;
			}
		}
	}
	return false;
}
Dispatcher.prototype.dispatch=function(event){
	if ( this.events[event] ) {
		var listeners = this.events[event], len = listeners.length;
		while ( len-- ) {
			listeners[len](this);	//callback with self
		}		
	}
}
