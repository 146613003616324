var clickEvent = 'click';//((document.ontouchstart!==null)?'click':'touchstart');
var mouseHoverEvent = 'mouseover';
var mouseOutEvent = 'mouseout';
var translator;
var mapApp;
var analytics;
var entranceSwitch = 'goto';
jQuery(document).ready(function() {
	if(Cookies.get(CONFIG['COOKIE_NAME'])){
		$('body').removeClass('anonymous').addClass('userlogin');
	}
	translator = new AppTranslator(LANG,LANG_LABELS);
        
	var mapApp_params = {
		'vg':{
			'vgMapUrl':CONFIG["VG_MAP_BUNDLE"],
			'vgMapContainerId':CONFIG["VG_CONTAINER_ID"],
			'initialFloorName':CONFIG["VG_INITIAL_FLOOR_NAME"],
			'initialMapId':CONFIG["VG_INITIAL_MAP_ID"],
			'defaultCameraRadius':CONFIG["VG_DEFAULT_CAM_RADIUS"],
			'defaultBuildingCameraRadius':CONFIG["VG_DEFAULT_BUILD_CAM_RADIUS"],
			'centerCameraRadius':CONFIG["VG_CENTER_CAM_RADIUS"],
			'defaultFloorCameraPitch':CONFIG["VG_DEFAULT_FLOOR_CAM_PITCH"]
		},
		'google':{
			'ggMapContainerId':'gg_map_container',
			'initialLatLong':CONFIG["GG_INIT_LAT_LNG"],
			//'overlayPoly':CONFIG["GG_OVERLAY_POLY"],
			'defaultCameraZoom':CONFIG["GG_DEFAULT_CAM_ZOOM"]
		},
		'defaultNavView': CONFIG["DEFAULT_NAV_VIEW"],
		'defaultMapView': CONFIG["DEFAULT_MAP_VIEW"],
		'translator':translator
	}
	mapApp = new mapSDK(mapApp_params);
	mapApp.init();

	mapApp.addEventlistener("MapSDK.settingsLoaded", function(){initializeAnalytics()});
	mapApp.addEventlistener("MapSDK.jsonDataLoaded", function(){ 
		if(deep_linking_request === true){
			var removeParamsFromURL = parseInt(mapApp.getSetting('hide_deep_linking_parameters'));
			if ((typeof(removeParamsFromURL) === 'undefined') || (isNaN(removeParamsFromURL))) {
				removeParamsFromURL = false;
			} else {
				removeParamsFromURL = (removeParamsFromURL === 1);
			}
			deepLinkingRequest(removeParamsFromURL);
		}
	});

	$('header').css('height','100px');
	$(window).on('resize', function(){
				var x = $(".right-container").position();
				if(x.top == 0){
					$('header').css('height','100px');
					$('.right-omnibox').css('top','140px');
				}else{
					$('header').css('height','250px');
					$('.right-omnibox').css('top','290px');
				}
	});
	function initializeAnalytics(){
		var language_string = LANG.split('_');
		var language = language_string[0];
		var isProduction = Number(CONFIG["IS_PRODUCTION"]);
		var enableLibrary = parseInt(mapApp.getSetting('enable_analytics_service'));
		analytics = new CNXAnalytics({
			'appID':3, //Web App
			'clientID':1082, // Hospital for Special Surgery
			'isProduction': isProduction,
			'enableLibrary': enableLibrary,
			'language': language,
			'authorization':'1Hn12UR12*4%as'
		});
		//Track event
		analytics.sendEvent({
		'typeID': 1 //App Open
		});
	}
	/**** Open download app modal ***/
	$("#download-app-md").modal();

	/**** Navigation menu ***/
	$('.menu .nav li a').on(clickEvent,function(e){
		if(e.preventDefault){e.preventDefault();}
		var tab = $(this);
	    if(tab.parent('li').hasClass('active')){
	        window.setTimeout(function(){
	            $(".tab-pane").removeClass('active');
	            tab.parent('li').removeClass('active');
	        },1);
	    }
	});

	/*
	* Events for Change Map view (Google, Building, Floor, Campus Map)
	*/
	$("#change_view_select").on(clickEvent,function(e){
		if($('body').hasClass('routing') || $('.right-omnibox').hasClass('navigation')) {
			e.stopPropagation();
			return false;
		}else{
			$('#change_view_select .views-container li').on(clickEvent,function(e){
				if(e.preventDefault()){e.preventDefault();}
				$(this).parent().removeClass('open');
				var modeSelector = $('#change_view_select');
			    var currentState = mapApp.getCurrentExploreState();
			    var floorID = (!currentState)?CONFIG["VG_INITIAL_FLOOR_NAME"]:currentState.floorID;
			   	var buildingID = (!currentState)?CONFIG["VG_INITIAL_BUILDING_NAME"]:currentState.buildingID;
			    var target = $(this).data('target');
			    mapApp.changeFloor({target:target,index:floorID,building:buildingID}).done(function(){
			    		modeSelector.find('.views-container li').removeClass('hide');
			    		var modeElem = modeSelector.find('.views-container li[data-target="'+target+'"]');
		        		modeSelector.find('#map-view').html(modeElem.attr('data-text'));
			    });
			});
		};
	});

	/*
	* Events for Change Building
	*/
	$("#change_building_select .views-container li").on(clickEvent, function (e) {
		if ($('body').hasClass('routing') || $('.right-omnibox').hasClass('navigation')) {
			//e.stopPropagation();
			return false;
		} else {
			// $('#change_building_select .views-container li').on(clickEvent, function (e) {
			//if(e.stopPropagation){e.stopPropagation();}
			if (e.preventDefault()) {
				e.preventDefault();
			}
			$(this).parent().removeClass('open');
			$(this).parent().parent().removeClass('open');
			$(this).parent().parent().find('.dropdown-toggle').attr('aria-expanded', "false");
			var buildingId = $(this).attr('data-vg-building-id');
			mapApp.changeFloor({
				target:'building',
				building:buildingId
			}).done(function () {
				
			});
			// });
		}
	});

	/*
	* Events for Change Floor
	*/
	//$(document).on(clickEvent,'#change_floor_select',function(e){
	$("#change_floor_select").on(clickEvent,function(e){
		if($('body').hasClass('routing') || $('.right-omnibox').hasClass('navigation')) {
			e.stopPropagation();
			return false;
		}else{
			$('#change_floor_select .views-container li').on(clickEvent,function(e){
				if(e.stopPropagation){e.stopPropagation();}
				if(e.preventDefault){e.preventDefault();}
				$(this).parent().removeClass('open');
				$(this).parent().parent().removeClass('open');
				$(this).parent().parent().find('.dropdown-toggle').attr('aria-expanded',"false");
				$('body').addClass('vg-map').removeClass('gg-map'); 
				var floorID =  jQuery(this).attr('data-vg-floor-id');
				if (floorID !== '')
				{
					mapApp.changeFloor({
						target:'floor',index:floorID
					}).done(function () {
						
					});
				}	    
			});
		}
	});

	$('#zoom-in').on(clickEvent,function(e){
		if(e.preventDefault){e.preventDefault();}
		mapApp.zoomIn();
	});
	$('#zoom-out').on(clickEvent,function(e){
		if(e.preventDefault){e.preventDefault();}
		mapApp.zoomOut();
	});
	$('#tilt-control').on(clickEvent,function(e){
		if(e.preventDefault){e.preventDefault();}
		if($(this).hasClass('open')){
			$(this).removeClass('open');
			$('.tilt-bar-content').addClass('hide');
		}else{
			var value = mapApp.getCameraPitch();
			$('#tilt-slider-vertical').slider('setValue', value);
			$('.tilt-bar-content').removeClass('hide');
			$(this).addClass('open');
		}
	});

	$('#tilt-slider-vertical').slider({
      min:-90,
      max:0,
      step:1,
      orientation:'vertical',
      value:-70,
      tooltip:'hide',
      handle:'round'
    }).on('slideStop', function(ev){
    	mapApp.updateCameraPitch(ev.value);
    });

    $('.placeholder').on(clickEvent,function(e){
		$(this).addClass('hide');
		$('.data-panel').removeClass("open");
    	var parent = $(this).parents('.placeholder-ctn');
    	parent.find('.placeholder-input').focus();
    });

    $('#search-filter').on('focus',function(e){
    	$('.right-omnibox').addClass('explore');
    	if($(this).val().trim() === ''){
    		var parent = $(this).parents('.placeholder-ctn');
    		parent.find('.placeholder').addClass('hide');
    	}
    });

		$('.directory-bar .btn,.mini-directory .btn').on(clickEvent,function(e){
			$('.search-bar').removeClass(mapApp.getAllCategoriesClasses());
			var action = $(this).data('action');
			if (action) {
				$('.mini-directory').removeClass('open');
				$('.search-bar').addClass(action);
				$('.list-container').addClass('loading');
				$('#search-cat').val(action);
				var params = {};
				if(CATEGORIES.getParentCategories().includes(action)){
					params = {
						container:'.data-panel',
						categoryType:action,
						extraClass:'multilevel'
					}
				}else{
					params = {
						container:'.data-panel',
						category:action
					}
				}
				mapApp.drawFilterList(params);
				$('.list-container').removeClass('loading');
				$('#search-filter').focus();
			} else {
				var url = $(this).data('url');
				var target = $(this).data('target');

				if (url) {
					window.open(url, target ? target : '');
				}
			}

		});

	$('#clear-button').on(clickEvent,function(e){
		var inputSearchFilter = $('#search-filter').val();
		$('.list-container .data-panel').html('');
		$('.search-bar').removeClass(mapApp.getAllCategoriesClasses());
		$('.data-panel').removeClass("open");
		clearDestinationForm();
		clearFilters();
		if(inputSearchFilter.trim()===''){
			if (!CONFIG['EXPLORE_BAR_EXPANDED']) $('.right-omnibox').removeClass('explore');
			$('.search-bar .placeholder-ctn .placeholder').removeClass('hide');
		}else{
			$('#search-filter').focus();
		}
	});

	$('#card-clear-button').on(clickEvent,function(){
		$('.search-bar').removeClass(mapApp.getAllCategoriesClasses());
		clearDestinationForm();
		mapApp.clearDestinationPoint();
		clearFilters();
		$('.data-panel').removeClass("open");
		$('.list-container .data-panel').html('');
		$('.right-omnibox').removeClass('navigation');
		$('#search-filter').focus();
		mapApp.enableFloorNavPanel();
	});

	$('#card-info-collapse').on(clickEvent,function(e){
		$('.info-card-panel').toggleClass('collapse');
	});

	function clearDestinationForm(){
		$('#search-filter').val('');
	}

	$('.data-panel').on(clickEvent,function(e){
		var $that = $(e.target);
		if ($that[0].nodeName !== 'LI') $that = $that.parent();
		if($that.hasClass('empty-item') || ($that.context && $($that.context).hasClass('list-item-title'))){
			return;
		}else if($that.hasClass('outer') || $that.hasClass('arrow')){
			var parent = ($that.hasClass('arrow'))?$that.parent('.list-item.outer'):$that;
			var action =parent.data('action');
			if(parent.hasClass('open')){
				parent.find('.inner-div.'+action).html('');
				parent.removeClass('open');
				$('#sub-cat').val('');
				$('.data-panel .list-item.outer').removeClass('hide');
			}else{
				$('.data-panel .list-item.outer').addClass('hide');
				$('#sub-cat').val(action);
				var params = {
					container:'.data-panel .inner-div.'+action,
					category:action,
					filterName: $('#search-filter').val()
				}
				mapApp.drawFilterList(params);
				parent.removeClass('hide').addClass('open');
			}
		}else{
			//Track event
			var search_string = $that.text().replace(/^(\s)*(-)*(\s)*(.*?)$/, "$4");
			analytics.sendEvent({
				'typeID': 13, //SEARCH
				'data': '{"search":"'+search_string+'"}'
			});
			$('.list-container .data-panel').html('');
			$('#search-filter').val($that.text());
			mapApp.clearDestinationPoint();
			mapApp.addDestinationPoint({position:$that.data('item-pos'),category:$that.data('cat'),map_type:$that.data('map-type')});
			var destination = mapApp.getDestination();
			var indoorAddress = mapApp.getIndoorAddress(destination);
			mapApp.fillInfoCard();

			//TODO: If I'm seraching viewing the outdoor map should I turn to indoor map
			if(destination.mapType === 'vg' && indoorAddress!== null && indoorAddress.map_id!== ''){
			mapApp.changeFloor({target:'floor',index:indoorAddress.vg_floor_id}).done(function(){
				mapApp.centerPoint(indoorAddress.map_id).done(function(){
					mapApp.drawDestinationLib();
				});
	    	});
			}else if(!destination.buildingAddress[0].prime){
				mapApp.drawDestinationLibOutdoor(destination);
        		mapApp.setCenterGG({lat:destination.buildingAddress[0].lat,lng:destination.buildingAddress[0].lng});
				if ($('body').hasClass('vg-map')){
					$('body').addClass('gg-map').removeClass('vg-map');
				}
			}


			$('.right-omnibox').removeClass('explore').addClass('navigation');
			mapApp.disableFloorNavPanel();
		}
	});
	$('.location-filter').on(clickEvent,function(e){
		var $that = $(e.target);
		var container = 'location-filter';
		if($that.hasClass('outer') || $that.hasClass('arrow') || $that.hasClass('icon')){
			var parent = ($that.hasClass('arrow'))?$that.parent('.list-item.outer'):($that.hasClass('icon'))?$that.parent('.list-item.outer'):$that;
			var action =parent.data('action');
			if(parent.hasClass('open')){
				parent.find('.inner-div.'+action).html('');
				parent.removeClass('open');
				$('.'+container+' .list-item.outer').removeClass('hide');
			}else{
				$('.'+container+' .list-item.outer').addClass('hide');
				var params = {
					container:'.'+container+' .inner-div.'+action,
					category:action,start:0
				}
				mapApp.drawFilterList(params);
				parent.removeClass('hide').addClass('open');
			}
		}else{
			$('.'+container+' .list-item.outer').removeClass('hide open');
			$('.'+container+' .list-item.outer .inner-div').html('');
			$('.location-filter').html('');
			$('#street-address').prop( "checked", false );
			$('.street-panel').addClass('hide');
			var action = ($('.locations-panel').hasClass('start'))?'start':'end';
			var itemPos = $that.data('item-pos');
			var itemId = $that.data('item-id');
			var itemCat = $that.data('cat');
			var itemText = $that.data('item-text');
			var itemMapId = $that.data('map-id');
			var itemMapType = $that.data('map-type');
			var destination;
			if(action==='start'){
				mapApp.addOriginPoint({id:itemId,position:itemPos,category:itemCat,map_type:itemMapType});
				$('#start-point').val(itemText);
				$('.locations-panel').removeClass('start end full parking');

				if ($('#end-point').val()===''){
					$('#end-point').focus();
				}
			}else{
				mapApp.clearDestinationPoint();
				var isClosed = mapApp.checkClosedEntrances(itemId);
				if (isClosed) {
					entranceSwitch = 'destination';
					itemText = changeTargetToOpenEntrance();
				}
				mapApp.addDestinationPoint({id:itemId,position:itemPos,category:itemCat,map_type:itemMapType});
				destination = mapApp.getDestination();
				var indoorAddress = mapApp.getIndoorAddress(destination);
				if(destination.mapType === 'vg' && indoorAddress!== null && indoorAddress.map_id!== ''){
					mapApp.changeFloor({target:'floor',index:indoorAddress.vg_floor_id}).done(function(){
						mapApp.centerPoint(indoorAddress.map_id).done(function(){
							mapApp.drawDestinationLib();
						});
			    	});
				}else if(destination.mapType === 'gg'){
					mapApp.drawDestinationLibOutdoor(destination);
				}
				$('#end-point').val(itemText);
				$('.locations-panel').removeClass('start end full parking');
				if($('#start-point').val()===''){
					$('#start-point').focus();
				}
			}
			mapApp.updatePanels();
		}
	});
	
	function changeTargetToOpenEntrance() {
		var defautEntrance = mapApp.getDefaultEntrance();
		var pointData = {
			id:	defautEntrance.id,
			position: defautEntrance.position,
			category: defautEntrance.category,
			map_type: defautEntrance.map_type
		}
		return defautEntrance.name;
	}

	$('.map-instructions').on(clickEvent,function(e){
		var $that = $(e.target);
		if($that.hasClass('list-item') || $that.parents('.inner-panel').length>0){
			var parent = ($that.hasClass('list-item'))?$that:$that.parents('.inner-panel');
			$('.map-instructions-panel').removeClass('preview step-by-step').addClass('step-by-step');
			mapApp.gotoInstruction(parent.data('index'));
			//Track event
			var lastInstructionIndex = $('.map-instructions ul.level-panel:last li:last').data('index');
			if($that.data('index') == 0){
				var location = mapApp.getOrigin();
				analytics.sendEvent({
					'typeID': 14, //ROUTE_START
					'data': '{"location":"'+location.name.replace(/^(\s)*(-)*(\s)*(.*?)$/, "$4")+'"}'
				});
			}else if ($that.data('index') >= lastInstructionIndex){
				var location = mapApp.getDestination();
				analytics.sendEvent({
					'typeID': 15, //ROUTE_END
					'data': '{"location":"'+location.name.replace(/^(\s)*(-)*(\s)*(.*?)$/, "$4")+'"}'
				});
			}
		}else if($that.hasClass('level-head') || $that.parents('.level-head').length>0){
			var $head = ($that.hasClass('level-head'))?$that:$that.parents('.level-head');
			var mapMode = mapApp.getNavigationMode();
			if(!mapMode && !$head.hasClass('open')){ // In preview mode and click to open => should display stage
				mapApp.displayStage($head.data('index'));
				$head.addClass('open');
			}else if(mapMode && $head.hasClass('open') && $head.find('.list-item.active').length > 0){ // In step-by-step mode and click to close => should resume stage if instruction in open stage
				mapApp.displayStage($head.data('index'));
				$head.removeClass('open');
				$('.map-instructions-panel').removeClass('preview step-by-step').addClass('preview');
			}else{
				$head.toggleClass('open');
			}
		}
	});


	$(document).on(clickEvent, '.address-data-card .list-group .title-panel' , function() {
		var parent = $(this).parent();
		parent.toggleClass('closed');
	});

	$('#filter-button').on(clickEvent,function(e){
		mapApp.drawFilterData();
		$('.right-omnibox').removeClass('explore').addClass('filtering');
	});

	$('#locations-button').on(clickEvent,function(e){
		$('#end-point').val('');
		$('#start-point').val('');
		$('.right-omnibox').removeClass('explore').addClass('locations');
	});

	$("#change_view_select > ul, #change_floor_select > ul").click(function(e){
		if(e.preventDefault()){e.preventDefault();}
    	var $that = $(e.target);
    	var $dropdown = $that.parents('.dropdown');
	    $dropdown.find('.btn:first-child').html('<span class="drop-btn-text">'+$that.text()+'</span>'+'<span class="caret"></span>');
	    $dropdown.find('.btn:first-child').val($that.text());
	    $dropdown.find('.btn:first-child').attr('data-id',$that.attr('data-id'));
	});
	
	$("#change_building_select > ul").click(function(e){
		if(e.preventDefault()){e.preventDefault();}
		var $that = $(e.target);
		$('#map-building').text($that[0].textContent)
	});

	$(".dropdown-menu").click(function(e){
		if(e.preventDefault()){e.preventDefault();}
		var $that = $(e.target);
		var $dropdown = $that.parents('.dropdown');
		$dropdown.find('.btn:first-child').html('<span class="drop-btn-text">'+$that.text()+'</span>'+'<span class="caret"></span>');
		$dropdown.find('.btn:first-child').val($that.text());
		$dropdown.find('.btn:first-child').attr('data-id',$that.attr('data-id'));
	});

	$("#cancel-filter-btn").on(clickEvent,function(e){
		var searchParams = {
			container:'.data-panel',
			category:'staff'
		}
		clearFilters();
		mapApp.drawFilterList(searchParams);
		$('.right-omnibox').removeClass('filtering').addClass('explore');
	});
	$("#clear-filters").on(clickEvent,function(e){
		clearFilters();
	});
	$("#save-filter-btn").on(clickEvent,function(e){
		var searchParams = {
			container:'.data-panel',
			category:'staff'
		}
		var params={};
		var institutionId = $("#filter-institution").attr('data-id');
		var buildingId = $("#filter-building").attr('data-id');
		var siteId = $("#filter-site").attr('data-id');
		var classifications = $("#filter-form input:checked").filter(function( index ) {
		    return $(this).attr( "data-id" ) !== '0';
		});
		if(buildingId!==''){
			params['building'] = mapApp.getBuildingById(buildingId);
			searchParams['building'] = buildingId;
		}
		params['classification'] = [];
		searchParams['classification'] = [];
		$.each(classifications,function(key,value){
			var id = $(value).attr('data-id');
			params.classification.push(mapApp.getClassificationById(id));
			searchParams.classification.push(id);
		});
		saveStaffFilters(params);
		mapApp.setStaffFilters(params);
		mapApp.drawFilterList(searchParams);
	});

	function saveStaffFilters(params){
		var dataSaved = false;
		$('.mini-filter-container').html('');
		var institutionId = $("#filter-institution").attr('data-id');
		var buildingId = $("#filter-building").attr('data-id');
		var siteId = $("#filter-site").attr('data-id');
		var classifications = $("#filter-form input:checked").filter(function( index ) {
		    return $(this).attr( "data-id" ) !== '0';
		});

		if(params['institution']){
			dataSaved = true;
			var institution = params['institution'];
			$('.mini-filter-container').append('<span class="filter-pick" data-type="institution" data-id="'+institution.id+'">'+institution.name+'<span class="icon cross white"></span></span>');
		}
		if(params['building']){
			dataSaved = true;
			var building = params['building']
			$('.mini-filter-container').append('<span class="filter-pick" data-type="building" data-id="'+building.id+'">'+building.name+'<span class="icon cross white"></span></span>');
		}
		if(params['site']){
			dataSaved = true;
			var site = params['site'];
			$('.mini-filter-container').append('<span class="filter-pick" data-type="site" data-id="'+site.id+'">'+site.name+'<span class="icon cross white"></span></span>');
		}
		if(params['classification'] && params.classification.length >0){
			$.each(params.classification,function(key,value){
				dataSaved = true;
				var clas =  value;
				$('.mini-filter-container').append('<span class="filter-pick" data-type="classification" data-id="'+clas.id+'">'+clas.name+'<span class="icon cross white"></span></span>');
			});
		}
		var clickFunction = function(e){
			var $filterContainer = $('.mini-filter-container');
			var staffFilters;
			var searchParams = {
				container:'.data-panel',
				category:'staff'
			}
			var filter = $(this).parent();
			filter.remove();
			mapApp.removeStaffFilter({type:filter.attr('data-type'),id:filter.attr('data-id')});
			staffFilters = mapApp.getStaffFilters();
			searchParams['institution'] = (staffFilters.institution && staffFilters.institution!=null)?staffFilters.institution.id:null;
			searchParams['building'] = (staffFilters.building && staffFilters.building!=null)?staffFilters.building.id:null;
			searchParams['site'] = (staffFilters.site && staffFilters.site!=null)?staffFilters.site.acronym:null;
			if(staffFilters['classification'] && staffFilters.classification !== null && staffFilters.classification.length > 0){
				searchParams.classification = [];
				$.each(staffFilters.classification,function(key,value){
					searchParams.classification.push(value.id);
				});
			}

			if($filterContainer.children().length === 0){
				$filterContainer.addClass('hide');
			}
			mapApp.drawFilterList(searchParams);
		}
		$('.filter-pick .icon').bind(clickEvent,clickFunction);
		if(dataSaved){
			$('.mini-filter-container').removeClass('hide');
		}else{
			$('.mini-filter-container').addClass('hide');
		}
		$('.right-omnibox').removeClass('filtering').addClass('explore');
	}

	function clearFilters(){
		var btns = $('#filter-form .field-form .btn');
		$('#search-cat').val('');
		$('#sub-cat').val('');
		$.each(btns,function(i,b){
			b = $(b);
			b.html(b.data('default')+'<span class="caret"></span>');
			b.val(b.data('default'));
			b.attr('data-id','');
		});
		var checks = $('#filter-form input[type="checkbox"]').filter(function( index ) {
		    return $(this).attr( "data-id" ) !== '0';
		}).removeAttr('checked');
		$('.mini-filter-container').addClass('hide');
		mapApp.clearStaffFilters();
	}

	$('.search-bar .category').on(clickEvent,function(e){
		$('.mini-directory').toggleClass('open');
	});

	$('.poi-item').on(clickEvent, function () {
		var icon = $(this).find('.icon');
		var isActive = icon.hasClass('active');
		var map =$(this).data('map');
		var type = $(this).data('type');
		if(isActive){
			icon.removeClass('active');
			mapApp.removePOIs(map,type);
		}else{
			//Track event
				analytics.sendEvent({
					'typeID': 7, //SHOWING_MARKER_IN_MAP
					'data': '{"MARKER_NAME":"'+type+'"}'
				});
				icon.addClass('active');
				mapApp.addPOIs(map,type);
		}
	});

	$("#search-filter").typeWatch({
	    callback: function (value) { 
	    	var cat = $('#search-cat').val();
	    	var subcat = $('#sub-cat').val();
	    	var staffFilters = mapApp.getStaffFilters();
	    	var params={
				container:'.data-panel',
				category:cat,
				filterName:value
			};
			if( (cat == 'poi' || cat == 'local-amenities' || cat == 'parking-main') && subcat !== ''){
				params['category']=subcat;
	    		params['container']='.data-panel .inner-div.'+subcat;
			}else if(cat == 'poi' || cat == 'local-amenities' || cat == 'parking-main'){
	    		params['category']=null;
	    		params['categoryType']=cat;
	    		params['extraClass']='multilevel';
			}else if('staff' && staffFilters){
				params['institution'] = (staffFilters.institution && staffFilters.institution!=null)?staffFilters.institution.id:null;
				params['building'] = (staffFilters.building && staffFilters.building!=null)?staffFilters.building.id:null;
				params['site'] = (staffFilters.site && staffFilters.site!=null)?staffFilters.site.acronym:null;
				if(staffFilters['classification'] && staffFilters.classification !== null && staffFilters.classification.length > 0){
					params.classification = [];
					$.each(staffFilters.classification,function(key,value){
						params.classification.push(value);
					});
				}
			}
	    	mapApp.drawFilterList(params);
	    },
	    wait: 300,
	    highlight: false,
	    captureLength: 0
	});
	$("#start-point,#end-point").typeWatch({
	    callback: function (value) {
	    	fillAutoSearchResults(value);
	    },
	    wait: 300,
	    highlight: false,
	    captureLength: 0
	});
	$("#street-address").on('change',function(){
		var value = ($('.locations-panel').hasClass('start'))?$('#start-point').val():$('#end-point').val();
		fillAutoSearchResults(value);
	});
	function fillAutoSearchResults(value){
		var gSearch = $('#street-address').is(':checked');
    	var params={
			container:'.location-filter',
			filterName:value
		}; 
		if(gSearch && value.trim().length>0){
			mapApp.searchStreetAddress(params);
		}else if(value.trim().length>0){
			params['category']='';
		    mapApp.drawFilterList(params);
		}else{
			$('.location-filter').html('');
		}
	}
	$("#revert-points").on(clickEvent,function(){
		var routeDirection;
		var destination = mapApp.getDestination();
		var destName = $('#end-point').val();
		var origin = mapApp.getOrigin();
		var originAddress = origin.buildingAddress[origin.toAddress];
		var orgName = $('#start-point').val();
		mapApp.clearDestinationPoint();
		mapApp.clearOriginPoint();
		clearDestinationForm();
		clearRoutePointForm();
		$('#search-filter').val(orgName);
		$('#end-point').val(orgName);
		$('#start-point').val(destName);

		if(orgName !== ''){
			mapApp.addDestinationPoint({id:origin.id,position:origin.position,category:origin.category,map_type:origin.mapType,toAddress:origin.toAddress,map_id:origin.mapId});
			var indoorAddress = mapApp.getIndoorAddress(origin);
			if(origin.mapType === 'vg' /*&& mapApp.getVgMapStatus()==='STARTED'*/ && indoorAddress!== null && indoorAddress.map_id!== ''){
				mapApp.changeFloor({target:'floor',index:indoorAddress.vg_floor_id}).done(function(){
					mapApp.centerPoint(indoorAddress.map_id).done(function(){
						mapApp.drawDestinationLib();
					});
		    	});
			}else if(origin.mapType === 'gg'){
				mapApp.drawDestinationLibOutdoor(origin);
			}
		}
		if(destName !== ''){
			mapApp.addOriginPoint({id:destination.id,position:destination.position,category:destination.category,map_type:destination.mapType,toAddress:destination.toAddress,map_id:destination.mapId});
		}
		
		if(orgName !== '' && destName !== ''){
			routeDirection = mapApp.getRouteDirection();
		
			if(routeDirection === 'indoor-outdoor' || routeDirection === 'outdoor-indoor'){	
				var parkingListCat = $('#parking-options-input').val();
				var defaultEntrance = CONFIG['DEFAULT_ENTRANCE_MAPID'];
				var defaultPoint = (parkingListCat==='parking')?mapApp.getPreferedParking():mapApp.getOutdoorEntranceDetails(defaultEntrance);
				if(routeDirection === 'outdoor-indoor' && parkingListCat==='parking' && originAddress.gar_map_id!==''){
					defaultPoint = mapApp.getPreferedParkingByMapId(originAddress.gar_map_id);
				}
				
				var modeTranspBtn = $('.btn-transport-mode.active');
				var modePanel = modeTranspBtn.data('panel');
				var classMode = mapApp.getRouteDirection();

				$('#parking-options-input').val(parkingListCat);
				$('#main-garage').text(defaultPoint.name);
				$('#main-garage').attr('data-cat',parkingListCat);
				$('#main-garage').attr('data-position',defaultPoint.position);
				$('input[name="get-park"]').each(function(){
					mapApp.removePOIs('outdoor',$(this).data('action'));
				});
				$('.parking-list-panel .on-map').removeClass('active');
				loadParkingList(parkingListCat);

				$('.transport-panel').removeClass('outdoor-indoor indoor-outdoor outdoor-outdoor');
				$('.'+modePanel).addClass(classMode);
			}
		}else{
			$('.transport-panel').removeClass('outdoor-indoor indoor-outdoor outdoor-outdoor');
		}	
	});

	$(document).on(clickEvent, '.goto-card-btn' , function() {
		//Track event
		analytics.sendEvent({
			'typeID': 4 //GOTO
		});
		var destObj =mapApp.getDestination();
		var pickedAddress = $(this).data('index');
		mapApp.addDestinationPoint({position:destObj.position,category:destObj.category,map_type:destObj.mapType,toAddress:pickedAddress});
		var isClosed = mapApp.checkClosedEntrances(destObj.id);
		if (isClosed) {
			entranceSwitch = 'goto'
		 	destObj = rerouteToOpenEntrance(destObj);
		}
		mapApp.addDestinationPoint({
			position:destObj.position,
			category:destObj.category,
			map_type:destObj.mapType,
			toAddress:pickedAddress});
		$('#end-point').val(destObj.name);
		$('#start-point').val('');
		$('.transport-panel').addClass('hide').removeClass('outdoor-indoor indoor-outdoor outdoor-outdoor my-park my-exit');
		$('.right-omnibox').removeClass('navigation').addClass('locations');
		$('#start-point').focus();
		mapApp.enableFloorNavPanel();
	});

	function rerouteToOpenEntrance(destObj){
		var openEntrance = mapApp.getDefaultEntrance()
		destObj.position = openEntrance.position;
		destObj.name = openEntrance.name
		return destObj;
	}

	$('#end-point,#start-point').on('focus',function(e){
		var dest= mapApp.getDestination();
		var org = mapApp.getOrigin();
		var action = $(this).data('action');
		$('.location-filter').html('');

		$('.street-panel').removeClass('hide');
		if((dest!==null && dest.category==='outdoor' && action==='end') || org!==null && org.category==='outdoor' && action==='start'){
			$('#street-address').prop('checked', true);
		}
		$('.locations-panel').removeClass('start end full parking').addClass(action);
		$('.mode-transport-panel').addClass('hide');
		$('.transport-panel').addClass('hide');
	});
	$('#end-point,#start-point').on('blur',function(e){
		/*if($('#start-point').val().trim()!=='' && $('#end-point').val().trim()!==''){
			$('.locations-panel').removeClass('start end');
		}*/
	});

	$('.right-omnibox').bind('DOMSubtreeModified', function(e) {
		if(!$('.right-omnibox').hasClass("locations")) {
			$("#street-address").prop("checked", false);
		}
	});

	$('#clear-directions').on(clickEvent,function(e){
		$('#end-point').val('');
		$('#start-point').val('');
		$('.right-omnibox').removeClass('locations');
		$('.locations-panel').removeClass('full start end parking');
		$('.mode-transport-panel').addClass('hide');
		$('.transport-panel').addClass('hide');
		$('.data-panel').removeClass("open"); 
		clearRoutePointForm();	
		clearDestinationForm();
		mapApp.clearOriginPoint();
		mapApp.clearDestinationPoint(); 
		clearFilters();
		$('.list-container .data-panel').html('');
		$('#search-filter').val('');
		$('#search-filter').focus();
		$('#wheelchair-route-check').prop('checked', false);
		mapApp.setHandicapRoute(false);
	});
	$('#locations-form .marker').on(clickEvent,function(e){
		$('.locations-panel').removeClass('start end').addClass($(this).data('action'));
		$('#'+$(this).data('action')+'-point').focus();
	});
	function sendToRoute(){
		var modeTrans = $('.btn-transport-mode.active').data('action');
		var routeDirection = mapApp.getRouteDirection();
		var $transportPoint = $('#main-garage');
		var handicap = $("#wheelchair-route-check").is(':checked')?true:false;
		mapApp.setHandicapRoute(handicap);
		$('input[name="get-park"]').each(function(){
			mapApp.removePOIs('outdoor',$(this).data('action'));
		});
		$('.parking-list-panel .on-map').removeClass('active');
		$('#trans-mode-btn').removeClass('transit driving');
		mapApp.clearRouteWaypoints();

		if(routeDirection === 'outdoor-outdoor'){
			$('#trans-mode-btn').addClass(modeTrans);
		}else if(routeDirection === 'indoor-outdoor'){
			$("#download-app-md").modal()
			//Get selecte garage-options
			if (routeDirection == "indoor-outdoor" && $('input[name=get-park]:checked').length === 0 && modeTrans === 'driving') {
				alert('Please select how are you returning to your car?');
				$('body').removeClass('loading');
				return;
			}
			mapApp.addRouteWaypoint({position:$transportPoint.attr('data-position'),mode:modeTrans,category:$transportPoint.attr('data-cat')});
			$('#trans-mode-btn').addClass(modeTrans);
		}else if(routeDirection === 'outdoor-indoor'){
			mapApp.addRouteWaypoint({position:$transportPoint.attr('data-position'),mode:modeTrans,category:$transportPoint.attr('data-cat')});
			$('#trans-mode-btn').addClass(modeTrans);
		}

		mapApp.setModeTransportation(modeTrans);
		mapApp.computeRoute().done(function(){
			//Track event
			var typeID = routeDirection=='indoor-indoor'?8:9;
			var origin = mapApp.getOrigin();
			var destination = mapApp.getDestination();
			analytics.sendEvent({
				'typeID': typeID, //SHOWING_ROUTE_IN_MAP, SHOWING_GOOGLE_MAPS_OUTSIDE_ROUTE
				'data': '{"route": "source='+origin.name.replace(/^(\s)*(-)*(\s)*(.*?)$/, "$4")+', destination='+destination.name.replace(/^(\s)*(-)*(\s)*(.*?)$/, "$4")+'"}'
			});
			$('.map-instructions-panel').removeClass('preview step-by-step').addClass('preview');
			$('.explore-bar').removeClass('expand');
			$('.right-omnibox').removeClass('locations').addClass('routing');
			$('body').addClass('routing');
			mapApp.disableFloorNavPanel();
		}).fail(function(status){
			alert(status.message);
		});
		$('body').removeClass('loading');
	}
	//We add and interval because if vg is not loaded. We got an error
	$('#goto-route-btn').on(clickEvent,function(e){
		if(mapApp.getVgMapStatus()==='STARTED'){
			sendToRoute();
		}else{
			mapApp.requestLoadVGMap({context:this,process:sendToRoute});
		}
	});

	$('#instructions_prev_button').on(clickEvent,function() {
		mapApp.displayPrevInstruction();
		//Track event
		var currentInstruction = $('.list-item.instruction.active').data('index');
		if (currentInstruction <= 0){
			var location = mapApp.getOrigin();
			analytics.sendEvent({
				'typeID': 14, //ROUTE_START
				'data': '{"location":"'+location.name.replace(/^(\s)*(-)*(\s)*(.*?)$/, "$4")+'"}'
			});
		}    
	});

	$('#instructions_next_button').on(clickEvent,function() {
		mapApp.displayNextInstruction();
		//Track event
		var currentInstruction = $('.list-item.instruction.active').data('index');
		var lastInstructionIndex = $('.map-instructions ul.level-panel:last li:last').data('index');
		if (currentInstruction >= lastInstructionIndex){
			var location = mapApp.getDestination();
			analytics.sendEvent({
				'typeID': 15, //ROUTE_END
				'data': '{"location":"'+location.name.replace(/^(\s)*(-)*(\s)*(.*?)$/, "$4")+'"}'
			});
		}    
	});
	$('#stage_prev_button').on(clickEvent,function() {
		mapApp.displayPrevStage();	    
	});

	$('#stage_next_button').on(clickEvent,function() {
		mapApp.displayNextStage();	    
	});


	$('#clear-route-btn').on(clickEvent,function(e){
		mapApp.clearRoute();
		clearRoutePointForm();
		clearDestinationForm();
		clearFilters();
		$('.search-bar').removeClass(mapApp.getAllCategoriesClasses());
		(!CONFIG['EXPLORE_BAR_EXPANDED']) ? $('.right-omnibox').removeClass('routing') :
			$('.right-omnibox').removeClass('routing').addClass('explore');
		$('.data-panel').removeClass('open');
		$('#map-instructions').html('');
		$('.locations-panel').removeClass('start end full parking');
		$('.transport-panel').addClass('hide').removeClass('outdoor-indoor indoor-outdoor outdoor-outdoor my-park my-exit');
		$('.btn-transport-mode').removeClass('active');
		$('.btn-transport-mode[data-action="driving"]').addClass('active');
		$('.transport-panel,.mode-transport-panel').addClass('hide');
		$('.map-instructions-panel').removeClass('preview step-by-step').addClass('preview');
		mapApp.clearOriginPoint();
		mapApp.clearDestinationPoint();
		mapApp.setCenterPosition();
		$('.list-container .data-panel').html('');
		$('#end-point').val('');
		$('#start-point').val('');
		mapApp.enableFloorNavPanel();
		$('#wheelchair-route-check').prop('checked', false);
		mapApp.setHandicapRoute(false);
	});
	$('#edit-route').on(clickEvent,function(e){
		$('.right-omnibox').removeClass('routing').addClass('locations');
		mapApp.editRoute();
		mapApp.clearRoute();
		mapApp.enableFloorNavPanel();
	});
	$('.share-panel .btn').on(clickEvent,function(e){
		var action = $(this).data('action');
		action = (action==='send-to-phone')?'.send-to-print':'.send-to-phone';
		$(this).parents('.share-panel').find('.share-toggle'+action).addClass('hide');
		$(this).parent('.btn-group-addon').find('.share-toggle').toggleClass('hide');
	});
	$('.share-panel .share-toggle a').on(clickEvent,function(e){
		e.preventDefault();
		if ($(this).data('action') == 'print') {
			//Track event
			analytics.sendEvent({
				'typeID': 21 //Print Directions
			});
		}
		
		if ($(this).data('action') == 'print') {
			mapApp.sendDirections({
				action: 'PRINT',
				pdf_mode: $(this).data('type')
			}).then(function (params) {
				var queryString = mapApp.generateLink(params);
				window.open("export-route.php" + queryString, "_bank");
			}).catch(function (err) {
				console.log(err);
			});
		}
	});
	$('.mobile-link').on(clickEvent,function(e){
		var typeID = ($(this).attr('id') == "ios-app-link")?23:22;
		//Track event
		analytics.sendEvent({
			'typeID': typeID //App Store downloads, Google Play downloads
		});
	});
	$('#parking-options').on(clickEvent,function(e){
		var parkingListCat = $('#parking-options-input').val();
		if(!$('.locations-panel').hasClass('parking')){
			loadParkingList(parkingListCat);
		}
		$('.locations-panel').toggleClass('parking');
	});
	$('input[name="get-park"]').on('change',function(e){//#exit-options,#garage-options
		if(this.checked) {
	        var parkingListCat = $(this).data('action');
			var defaultEntrance = CONFIG['DEFAULT_ENTRANCE_MAPID'];
			var defaultPoint = (parkingListCat==='parking')?mapApp.getPreferedParking():mapApp.getOutdoorEntranceDetails(defaultEntrance);
			$('#parking-options-input').val(parkingListCat);
			$('#main-garage').text(defaultPoint.name);
			$('#main-garage').attr('data-cat',parkingListCat);
			$('#main-garage').attr('data-position',defaultPoint.position);
			$('input[name="get-park"]').each(function(){
				mapApp.removePOIs('outdoor',$(this).data('action'));
			});
			$('.parking-list-panel .on-map').removeClass('active');
			loadParkingList(parkingListCat);
			$('.locations-panel').addClass('parking');
	    }
	});
	function loadParkingList(category){
		var parkingListCat = $('#parking-options-input').val();
		mapApp.fillParkingList({container:'.parking-list-panel',category:parkingListCat});
	}
	function clearRoutePointForm(){
		$('#parking-options-input').val('parking');
		$('input[name="get-park"]').prop('checked',false);
		$('#main-garage').text('');
		$('#main-garage').attr('data-cat','');
		$('#main-garage').attr('data-position','');
	}
	$('.parking-list-panel').on(clickEvent,function(e){
		var $that = $(e.target);
		if($that.hasClass('on-map')){
			var category = $that.data('cat');
			mapApp.changeFloor({target:'outdoor'}).done(function(){
				if(!$that.hasClass('active')){
					mapApp.addPOIs('outdoor',category);
				}
				$that.addClass('active');
	    	});
		}else if($that.hasClass('list-item')){
			var destination = mapApp.getDestination();
			//var classMode = mapApp.getRouteDirection();
			$('#main-garage').text($that.data('item-text'));
			$('#main-garage').attr('data-cat',$that.data('item-cat'));
			$('#main-garage').attr('data-position',$that.data('item-pos'));
			$('.locations-panel').removeClass('parking');
			$('input[name="get-park"]').each(function(){
				mapApp.removePOIs('outdoor',$(this).data('action'));
			});
			$('.parking-list-panel .on-map').removeClass('active');
			if(destination.category==='outdoor'){
				var garageClass = ($that.data('item-cat')==='parking')?'my-park':'my-exit';
				$('.parking-panel').addClass(garageClass);
			}
		}
	});
	$('.btn-transport-mode').on(clickEvent,function(e){
		if(!$(this).hasClass('active')){
			var panel = $(this).data('panel');
			var classMode = mapApp.getRouteDirection();
			$('input[name="get-park"]').prop('checked',false);
			$('.locations-panel').removeClass('parking');
			$('.transport-panel').addClass('hide').removeClass('outdoor-indoor indoor-outdoor outdoor-outdoor my-park my-exit');
			$('.btn-transport-mode').removeClass('active');
			$('.transport-panel').addClass('hide');
			$(this).addClass('active');

			if(classMode==='outdoor-outdoor'){
				$('.'+panel).addClass('hide');
			}else if(classMode==='indoor-outdoor' || classMode==='outdoor-indoor'){
				$('.'+panel).removeClass('hide');
				$('.'+panel).addClass(classMode);
				$('input[name="get-park"]').each(function(){
					mapApp.removePOIs('outdoor',$(this).data('action'));
				});
				$('.parking-list-panel .on-map').removeClass('active');
			}
		}
	});

	//Functions for this functionality are on validations.js file
	$('#send-phone-submit').on(clickEvent,function(e){
		var mode = $('#send-phone-mode').val();
		var $errorDiv = $('#send-to-phone-form .error-div');
		$errorDiv.text('');
		if($('#send-email-address').val().trim()==='' && $('#send-phone-number').val().trim()===''){
			clearForm('send-to-phone-form');
			$errorDiv.text($errorDiv.data('invalid'));
		}else if(!validateForm('send-to-phone-form')){
			var sendByEmail =($('#send-email-address').val().trim()!=='')?true:false;
			var sendByPhone =($('#send-phone-number').val().trim()!=='')?true:false;
			if(sendByEmail){analytics.sendEvent({'typeID': 20})} //Track Event Send2Email
			if(sendByPhone){analytics.sendEvent({'typeID': 16})} //Track Event Send2Phone
			var url = mapApp.getApiUrl();
			
			if (!sendByEmail && !sendByPhone) throw "Invalid params!";

			var obj = {
				pdf_mode: $("#send-phone-mode").val(),
			};

			if (sendByEmail) {
				obj.email = $('#send-email-address').val().trim();
				obj.action = 'SEND_TO_EMAIL';
				mapApp.sendDirections(obj).then(function (params) {
					submitForm({
						formURL: url,
						action: 'send_pdf_web',
						pget: params
					}).done(function (data) {
						completeSendEmail({formId: 'send-to-phone-form'}, data);
					});
				});
			}
			if (sendByPhone) {
				obj.phone = $('#send-phone-number').val().trim();
				obj.action = 'SEND_TO_PHONE';
				mapApp.sendDirections(obj).then(function (params) {
					submitForm({
						formURL: url,
						action: 'send_pdf_web',
						pget: params
					}).done(function (data) {
						completeSendSMS({formId: 'send-to-phone-form'}, data);
					});
				});
			}
		}
	});

	//Functions for this functionality are on validations.js file
	$('#send-link-submit').on(clickEvent,function(e){
		var mode = $( 'input[name="get-op"]:checked').data('action');
		var linkAndroid = mapApp.getSetting('share_google_store_link');
		var linkIOS = mapApp.getSetting('share_apple_store_link');
		var link = (mode === 'android') ? linkAndroid : ((mode === 'ios') ? linkIOS : '');
		var params ={formId:'send-banner-form',apiURL:mapApp.getApiUrl()};
		var action = 'phone';
		var toastDiv = $("#toast");
		var $inputText = $('#send-email-phone');
		var temp =  $inputText.val().trim();
		toastDiv.text('');
		toastDiv.removeClass('success');
		if(temp===''){
			toastDiv.text(toastDiv.data('invalid'));
			toastDiv.addClass("show");
			setTimeout(function(){ toastDiv.removeClass("show"); }, 3000);
		}else if(!validateEmail(temp) && !validatePhoneNumber(temp)){
			toastDiv.text(toastDiv.data('invalid'));
			toastDiv.addClass("show");
			setTimeout(function(){ toastDiv.removeClass("show"); }, 3000);
		}else{
			if(typeof(mode)!== "undefined" && mode=="android"){analytics.sendEvent({'typeID': 22})} // Track Event Google Play downloads
			if(typeof(mode)!== "undefined" && mode=="ios"){analytics.sendEvent({'typeID': 23})} //  Track Event App Store downloads
			action = (validateEmail(temp))?'email':'phone';
			switch(action){
				case 'email':
				var emailSubject = mapApp.getTranslatorTags().getLangLabel('DOWNLOAD_APP')
				emailSubject = emailSubject.replace(/’/g,"'")
				params.action = "send_email";
				params.parameters ={'email':temp,
				'name':temp,
				'subject':emailSubject,
				'body':encodeURIComponent(mapApp.getTranslatorTags().getLangLabel('HERE_IS_YOUR_LINK')+' '+link)}
				break;
				case 'phone':
				temp = temp.replace(/[-\(\) ]/g,'');
				params.action = "send_sms";
				params.parameters ={'number':CONFIG["COUNTRY_CODE"]+temp,'message':encodeURIComponent('Here is your link. Enjoy!: '+link)};
				break;
			}
			shareByEmailPhone(params)
		}
	});


	$('#send-to-phone-md').on('shown.bs.modal', function (e) {
		$("#send-phone-mode").val($(e.relatedTarget).data("type"));
	});

	$('#send-to-phone-md').on('hidden.bs.modal', function (e) {
		clearForm('send-to-phone-form');
	});

	$('#download-app-md').on('hidden.bs.modal', function (e) {
		clearForm('send-banner-form');
	});

	var countDownloadModalShows = 0;
	$('#download-app-md').on('show.bs.modal', function (e) {
		var force = $(e.relatedTarget).data('force') || false;
		if(countDownloadModalShows++ >= 0 && !force && !e.relatedTarget){
			return false;
		} 
		var linkAndroid = mapApp.getSetting('share_google_store_link');
		var linkIOS = mapApp.getSetting('share_apple_store_link');
		$('#android-app-link').attr('href', linkAndroid);
		$('#ios-app-link').attr('href', linkIOS);
	});

	$('.banner-close-btn').on(clickEvent,function(e){
		if(e.preventDefault){e.preventDefault();}
		var target = $(this).data('banner');
		$(target).removeClass('show');
	});

	$(function () {
	  $('[data-toggle="tooltip"]').tooltip();
	  $('[data-toggle="tooltip"]').mouseleave(function(){
		$(this).tooltip('hide')
	});
	  $('.collapse').collapse();

	  $('[data-toggle="slide-banner"]').addClass('show');
	});

	var deepLinkingTypes = {
		/*
		search: 'search',
		map: 'map',
		route: 'route',
		home: 'home',
		*/
		goto: 'goto'
	};

	$('#closedEntranceModal').on('hidden.bs.modal', function () {
		var openEntrance = mapApp.getDefaultEntrance();
		switch(entranceSwitch) {
			case "goto":
			mapApp.changeFloor({target:'floor',index:openEntrance.vg_floor_id}).done(function(){
				mapApp.centerPoint(openEntrance.map_id).done(function(){
					mapApp.drawDestinationLib();
				})
			})
			break;
			case "destination":
			var defaultEntrance = mapApp.getDefaultEntrance();
			var pointData = {
				id:defaultEntrance.id,
				position:defaultEntrance.position,
				category:defaultEntrance.category,
				map_type:defaultEntrance.map_type
			}
			mapApp.addDestinationPoint(pointData);
			destination = mapApp.getDestination();
			var indoorAddress = mapApp.getIndoorAddress(destination);
			if(destination.mapType === 'vg' && indoorAddress!== null && indoorAddress.map_id!== ''){
				mapApp.changeFloor({target:'floor',index:indoorAddress.vg_floor_id}).done(function(){
					mapApp.centerPoint(indoorAddress.map_id).done(function(){
						mapApp.drawDestinationLib();
					});
				});
			}else if(destination.mapType === 'gg'){
				mapApp.drawDestinationLibOutdoor(destination);
			}
			return defaultEntrance.name;
			break;
		}
	})
		
	function deepLinkingRequest(tryToRemoveParametersFromURL){ 

		if (tryToRemoveParametersFromURL && window.history) {
			if (typeof(window.history.pushState) === 'function') {
				var baseUrl = CONFIG["BASE_URL"];
				var compUrl = CONFIG["WEB_COMPONENT_URL"];
				var appUrl = baseUrl + compUrl;
				var currentPageTitle = document.getElementsByTagName("title")[0].innerHTML;
				var currentHistoryState = (typeof(window.history.state) !== 'undefined') ? window.history.state : null;
				window.history.pushState(currentHistoryState, currentPageTitle, appUrl);
			}
		}

		if(typeof(params_url.type) !== 'undefined'){
			switch (params_url.type){
				
				/*
				// Search
				case deepLinkingTypes.search:
					if((typeof(params_url.q) !== 'undefined') && (params_url.q !== '')){
						deepLinkingSearch(params_url.q);
					}
				break;
				
				// Map (indoor/outdoor)
				case deepLinkingTypes.map:
					if((typeof(params_url.map_id) !== 'undefined') && (params_url.map_id !== '')){
						deepLinkingMap(params_url.map_id, false);
					} else {
						if((typeof(params_url.map_ll) !== 'undefined') && (params_url.map_ll !== '')){
							deepLinkingMap(params_url.map_ll, true);
						}
					}
				break;
				
				// Route (indoor-indoor/outdoor-indoor)
				case deepLinkingTypes.route:
					//console.log(params_url);
					if((typeof(params_url.src_map_id) !== 'undefined') && (params_url.src_map_id !== '')){
						deepLinkingRoute({src_map_id:params_url.src_map_id,dest_map_id:params_url.dest_map_id});
					}else{
						if ((typeof(params_url.src_ll) !== 'undefined') && (params_url.src_ll !== '')) {
							deepLinkingRoute({
								src_ll: params_url.src_ll,
								dest_map_id: params_url.dest_map_id,
								transp_mode: params_url.transp_mode,
								pp_index: params_url.pp_index
							});
						}
					}
				break;
				*/
				
				// Go to
				case deepLinkingTypes.goto:
					if((typeof(params_url.map_id) !== 'undefined') && (params_url.map_id !== '')){
						var couldBeOutdoor = false;
						deepLinkingGoTo(params_url.map_id, couldBeOutdoor);
					} else {
						if((typeof(params_url.staff_id) !== 'undefined') && (params_url.staff_id !== '')){
							var couldBeOutdoor = true;
							deepLinkingGoTo(params_url.staff_id, couldBeOutdoor);
						}
					}
				break;
				
				// Invalid request
				default:
					console.log('Unrecognized query string parameters for deep linking:');
					console.log(params_url);
				break;

			}
		}

	}

	function deepLinkingErrorHandler(error_case) {
		var errMsg = '';
		switch(error_case) {
			case 'DL_STAFF_NOT_FOUND':
				errMsg = 'Staff not found for deep linking';
			break;
			case 'DL_PLACE_NOT_FOUND':
				errMsg = 'Map\'s place id not found for deep linking';
			break;
			case 'DL_UNRECOGNIZED_MAP_TYPE':
				errMsg = 'Unrecognized map type for place\'s deep linking';
			break;
			default:
				errMsg = 'Unknown error from deep linking';
			break;
		}
		console.warn(errMsg);
	}

	function deepLinkingGoToPlace(place) {

		$('.list-container .data-panel').html('');
		mapApp.clearDestinationPoint();
		mapApp.addDestinationPoint({position:place.position,category:place.category,map_type:'vg'});
		var destination = mapApp.getDestination();
		var indoorAddress = mapApp.getIndoorAddress(destination);
		mapApp.fillInfoCard();

		if(destination.mapType === 'vg' && indoorAddress!== null && indoorAddress.map_id!== ''){
			mapApp.changeFloor({target:'floor',index:indoorAddress.vg_floor_id}).done(function(){
				mapApp.centerPoint(indoorAddress.map_id).done(function(){
					$('body').removeClass('loading');
				});
	    	});
		} else if (!destination.buildingAddress[0].prime){
			var lat = place.building_address[0].lat;
			var lng = place.building_address[0].lng;
			var latLng = {'lat':lat,'lng':lng};
			mapApp.setCenterGG(latLng);
			mapApp.drawDestinationLibOutdoor(destination);
			if ($('body').hasClass('vg-map')){
				$('body').addClass('gg-map').removeClass('vg-map');
			}
		}

		$('#end-point').val(destination.name);
		$('#start-point').val('');
		$('.transport-panel').addClass('hide').removeClass('outdoor-indoor indoor-outdoor outdoor-outdoor my-park my-exit');
		$('.right-omnibox').removeClass('navigation').addClass('locations');
		mapApp.enableFloorNavPanel();
		$('#start-point').focus();

	}

	function deepLinkingGoTo(target_id, couldBeOutdoor) {

		if (couldBeOutdoor === true) { // is staff
			var staffFound = mapApp.getStaffFromId(target_id);
			if (staffFound.length > 0) {
				var staffObj = staffFound[0];
				if (staffObj.map_type.toLowerCase() === 'gg') {
					deepLinkingGoToPlace(staffObj);
				} else {
					if (staffObj.map_type.toLowerCase() === 'vg') {
						deepLinkingGoToPlace(staffObj);
					} else {
						deepLinkingErrorHandler('DL_UNRECOGNIZED_MAP_TYPE');
					}
				}
			} else {
				deepLinkingErrorHandler('DL_STAFF_NOT_FOUND');
			}
		} else {
			var mapIdsToData = mapApp.getMapIdsToData();
			var place = mapIdsToData[target_id];
			if (typeof(place) !== 'undefined') {
				deepLinkingGoToPlace(place);
			} else {
				deepLinkingErrorHandler('DL_PLACE_NOT_FOUND');
			}
		}

		deep_linking_request = false;

	}

	/*
	function deepLinkingShowIndoorMap(map_id) {
		var mapIdsToData = mapApp.getMapIdsToData();
		var point = mapIdsToData[map_id];
		if(typeof(point !== 'undefined')){
			$('.list-container .data-panel').html('');
			mapApp.clearDestinationPoint();
			mapApp.addDestinationPoint({position:point.position,category:point.category,map_type:'vg'});
			var destination = mapApp.getDestination();
			var indoorAddress = mapApp.getIndoorAddress(destination);
			mapApp.fillInfoCard();
			//TODO: If I'm seraching viewing the outdoor map should I turn to indoor map
			if(destination.mapType === 'vg' && indoorAddress!== null && indoorAddress.map_id!== ''){
				mapApp.changeFloor({target:'floor',index:indoorAddress.vg_floor_id}).done(function(){
					mapApp.centerPoint(indoorAddress.map_id).done(function(){
						$('body').removeClass('loading');
					});
		    	});
			}
			$('.right-omnibox').removeClass('explore').addClass('navigation');
			mapApp.disableFloorNavPanel();	
		}
		deep_linking_request = false;
	}

	function deepLinkingShowOutdoorMap(map_ll) {
		// Not documented as a use case
		// To-do: check if this option should be implemented
		console.log(map_ll);
		deep_linking_request = false;
	}

	function deepLinkingMap(map_location, outdoor){
		if (outdoor === true) {
			deepLinkingShowOutdoorMap(map_location);
		} else {
			deepLinkingShowIndoorMap(map_location);
		}
	}

	function deepLinkingSearch(q){
		$('.search-bar').find('.placeholder').addClass('hide');
		$('.right-omnibox').addClass('explore');
		$('#search-filter').val(q).focus();
		var params={
			container:'.data-panel',
			filterName:q
		};
		mapApp.drawFilterList(params);
		$('body').removeClass('loading');
		deep_linking_request = false;
	}

	function deepLinkingRoute(params){
		$('body').addClass('vg-map').removeClass('gg-map');
		var mapIdsToData = mapApp.getMapIdsToData();
		var src_params = {};
		var dest_params = {};

		var dest_point = mapIdsToData[params.dest_map_id]; //JC validate if map_ids are valid
		var dest_cxtData = mapApp.findCxtDataByPosCategory({category:dest_point.category, position:dest_point.position})	
		dest_params = {id:dest_cxtData.id,position:dest_cxtData.position,category:dest_cxtData.category,map_type:dest_cxtData.map_type};
		
		mapApp.addDestinationPoint(dest_params);
		$('#end-point').val(dest_cxtData.name);

		if(typeof(params.src_ll) !== 'undefined'){ //Starting point is outdoor
			//console.log(params);

			src_params = {
				map_type: 'gg', 
				coordinates: params_url.src_ll,
				building_address: [],
				toAddress: 0
			};

			mapApp.addOriginPointLatLng(src_params).done(function(obj){
				//console.log(obj);
				$('#start-point').val(obj.name);

				var transpMode = params.transp_mode;
				$('.btn-transport-mode[data-action="' + transpMode + '"]').addClass('active').siblings().removeClass('active');

				var parkingGarageIndex = params.pp_index;
				$('#parking-options-input').val((transpMode === 'driving') ? 'parking' : 'skip-parking');
				$('#main-garage').attr('data-cat', (transpMode === 'driving') ? 'parking' : 'entrance');

				if (parkingGarageIndex === '') {

					var defaultPosition = $('#main-garage').attr('data-position');
					
					if ((typeof(defaultPosition) === 'undefined') || (defaultPosition === null) || (defaultPosition === '')) {

						//preferedParkgin = mapApp.getPreferedParking();
						//defaultPosition = preferedParkgin.position;

						var origin = mapApp.getOrigin();
						var originAddress = origin.buildingAddress[origin.toAddress];
						var mainGarageCat = 'entrance';
						var defaultEntrance = CONFIG['DEFAULT_ENTRANCE_MAPID'];
						var defaultPoint = mapApp.getOutdoorEntranceDetails(defaultEntrance);
						var parkingName = '';
						var mainGarageName = translator.getLangLabel('NEAREST_ENTRANCE');
						var modeTranspBtn = $('.btn-transport-mode.active');
						var modePanel = modeTranspBtn.data('panel');

						$('#main-garage').text(mainGarageName);
						$('#main-garage').attr('data-cat',mainGarageCat);
						$('#main-garage').attr('data-position',defaultPoint.position);
						$('input[name="get-park"]').each(function(){
							mapApp.removePOIs('outdoor', $(this).data('action'));
						});
						$('.parking-list-panel .on-map').removeClass('active');
						loadParkingList(mainGarageCat);

						$('.transport-panel').removeClass('outdoor-indoor indoor-outdoor outdoor-outdoor my-park my-exit');
						$('.'+modePanel).addClass('outdoor-indoor');

					}

				} else {
					$('#main-garage').attr('data-position', parseInt(parkingGarageIndex));
				}

				deepLinkingSendRoute();
			});

		}else{
			var src_point = mapIdsToData[params.src_map_id]; //JC validate if map_ids are valid
			var src_cxtData = mapApp.findCxtDataByPosCategory({category:src_point.category, position:src_point.position});
			src_params = {id:src_cxtData.id,position:src_cxtData.position,category:src_cxtData.category,map_type:src_cxtData.map_type};
			mapApp.addOriginPoint(src_params);
			$('#start-point').val(src_cxtData.name);
			deepLinkingSendRoute();
		}
		deep_linking_request = false;
	}

	function deepLinkingSendRoute(){
		//console.log('Deep linking sent to route');
		if(mapApp.getVgMapStatus()==='STARTED'){
			sendToRoute();
		}else{
			mapApp.requestLoadVGMap({context:this,process:sendToRoute});
		}
		$('body').removeClass('loading');
		$('.locations-panel').addClass('full');
		deep_linking_request = false;
	}
	*/

	/*

	// The following functions allow the app to generate and update deep links on demand
	// By implement them, users can be able to copy and paste URLs while using the app
	// *** Not all cases are fully implemented *** | *** Not a use case for now ***

	function deepLinkingGenerateURL(params) {
		if (typeof(params.type) !== 'undefined') {
			//console.log(params);
			
			var baseUrl = CONFIG["BASE_URL"];
			var compUrl = CONFIG["WEB_COMPONENT_URL"];
			var appUrl = baseUrl + compUrl;

			switch(params.type) {
				
				// Search
				case deepLinkingTypes.search:
					if(typeof(params.search) !== 'undefined'){
						var searchUrl = appUrl + deepLinkingTypes.search + '/?q=';
						return searchUrl + params.search;
					} else {
						return appUrl;
					}
				break;
				
				// Map
				case deepLinkingTypes.map:
					if(typeof(params.map_id) !== 'undefined'){
						var mapUrl = appUrl + deepLinkingTypes.map + '/?map_id=';
						return mapUrl + params.map_id;
					} else {
						return appUrl;
					}
				break;
				
				// Route
				case deepLinkingTypes.route:
				break;
				
				// Go to
				case deepLinkingTypes.goto:
				break;
				
				// Open app
				case deepLinkingTypes.home:
					return appUrl;
				break;

				// Default
				default:
					return appUrl;
				break;

			}
		}
	}

	function deepLinkingUpdateAddressBar(new_url) {
		// https://html.spec.whatwg.org/multipage/browsers.html#the-history-interface
		var currentPageTitle = document.getElementsByTagName("title")[0].innerHTML;
		var currentHistoryState = window.history.state;
		window.history.pushState(currentHistoryState, currentPageTitle, new_url);
	}

	function deepLinkingUpdateURL(type, params) {

		var newUrl;

		switch(type) {

			case deepLinkingTypes.search: 
				newUrl = deepLinkingGenerateURL({type:type, search: params.search});
			break;

			case deepLinkingTypes.map: 
				newUrl = deepLinkingGenerateURL({type:type, map_id: params.map_id});
			break;

			case deepLinkingTypes.route: 
				//newUrl = deepLinkingGenerateURL({type:type});
			break;

			case deepLinkingTypes.goto: 
				//newUrl = deepLinkingGenerateURL({type:type});
			break;

			case deepLinkingTypes.home: 
				newUrl = deepLinkingGenerateURL({type:type});
			break;

			default: 
				newUrl = deepLinkingGenerateURL({type:type});
			break;

		}

		deepLinkingUpdateAddressBar(newUrl);

	}
	*/

});

$(document).on(clickEvent,function(event) {
	var $target = $(event.target);
	//console.log($target);
	if($target.parents('.zoom-tilt-group').length === 0){
		$('#tilt-control').removeClass('open');
		$('.tilt-bar-content').addClass('hide');
	}
	if($target.parents('.share-panel').length ===0){
		$('.share-panel .share-toggle').addClass('hide');
	}
	if($target.parents('.mini-directory').length ===0 && $target.parents('.min-cat-addon').length===0){
		$('.mini-directory').removeClass('open');
	}
});

//Trigger on a shuttle route POI is clicked
function goToStageFromOutdoorPOI(poiKey){
	var result = mapApp.goToStageFromOutdoorPOI(poiKey);
	if(result!==-1){
		$('.map-instructions-panel').removeClass('preview step-by-step').addClass('preview');
	}
}

//JC
//Hide getting the app banner after 6 seconds if user does not click on it
//setTimeout(function(){ $('#download-banner').removeClass('show'); }, CONFIG["HIDE_BANNER_TIME"]);
