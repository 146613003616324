var CATEGORIES = (function () {
    var ALL_CATEGORIES = [
        {name:'staff',id:'0',type:'general',label:'PHYSICIANS'},
        {type: "building-amenities", id: "267", name: "access-walkway", label: "ACCESS_WALKWAY"},
        {type: "building-amenities", id: "18", name: "amenities", label: "AMENITIES"},
        {type: "general", id: "10", name: "banks-atm", label: "BANKS_ATM"},
        {type: "department", id: "103", name: "billing", label: "BILLING"},
        {type: "building-amenities", id: "97", name: "charging-stations-electronics", label: "CHARGING_STATIONS_ELECTRONICS"},
        {type: "building-amenities", id: "13", name: "conference-rooms", label: "CONFERENCE_ROOMS"},
        {type: "building-amenities", id: "58", name: "courtyard", label: "COURTYARD"},
        {type: "department", id: "1", name: "department", label: "DEPARTMENT"},
        {type: "dining", id: "16", name: "dining", label: "DINING"},
        {type: "building-amenities", id: "15", name: "elevator", label: "ELEVATOR"},
        {type: "building-amenities", id: "4", name: "entrance", label: "ENTRANCE_EXIT"},
        {type: "general", id: "171", name: "gas-station", label: "GAS_STATION"},
        {type: "general", id: "91", name: "grocery-stores", label: "GROCERY_STORES"},
        {type: "general", id: "208", name: "gym", label: "GYM"},
        {type: "general", id: "37", name: "hotel-accommodation", label: "HOTEL_ACCOMMODATION"},
        {type: "department", id: "72", name: "imaging-diagnostic", label: "IMAGING_DIAGNOSTIC"},
        {type: "building-amenities", id: "29", name: "information-desk", label: "INFORMATION_DESK"},
        {type: "general", id: "19", name: "kiosk", label: "KIOSK"},
        {type: "general", id: "39", name: "library", label: "LIBRARY"},
        {type: "local-amenities", id: "326", name: "museums", label: "MUSEUMS"},
        {type: "building-amenities", id: "295", name: "outdoor-seating", label: "OUTDOOR_SEATING"},
        {type: "dining", id: "234", name: "pantry", label: "PANTRY"},
        {type: "general", id: "298", name: "park", label: "PARK"},
        {type: "general", id: "7", name: "parking", label: "PARKING"},
        {type: "general", id: "78", name: "patient-care-unit", label: "PATIENT_CARE_UNIT"},
        {type: "department", id: "5", name: "patient-services", label: "PATIENT_SERVICES"},
        {type: "building-amenities", id: "322", name: "patient-transport", label: "PATIENT_TRANSPORT"},
        {type: "general", id: "189", name: "pet-boarding", label: "PET_BOARDING"},
        {type: "general", id: "12", name: "pharmacy", label: "PHARMACY"},
        {type: "general", id: "241", name: "pick-up-zone", label: "PICK_UP_ZONE"},
        {type: "local-amenities", id: "36", name: "place-of-worship", label: "PLACE_OF_WORSHIP"},
        {type: "local-amenities", id: "327", name: "postal-services", label: "POSTAL_SERVICES"},
        {type: "department", id: "325", name: "rehabilitation-centers", label: "REHABILITATION_CENTERS"},
        {type: "building-amenities", id: "9", name: "restroom", label: "RESTROOM"},
        {type: "local-amenities", id: "237", name: "retail", label: "RETAIL"},
        {type: "local-amenities", id: "199", name: "services", label: "SERVICES"},
        {type: "department", id: "108", name: "specialties-practices", label: "SPECIALTIES_PRACTICES"},
        {type: "building-amenities", id: "22", name: "stairs", label: "STAIRS"},
        {type: "day-of-surgery", id: "323", name: "start-here-admissions", label: "START_HERE_ADMISSIONS"},
        {type: "day-of-surgery", id: "324", name: "surgery-centers", label: "SURGERY_CENTERS"},
        {type: "general", id: "27", name: "valet", label: "VALET"},
        {type: "dining", id: "21", name: "vending-machines", label: "VENDING_MACHINES"},
        {type: "building-amenities", id: "257", name: "waiting-area", label: "WAITING_AREA"},
        {type: "building-amenities", id: "162", name: "water-fountain", label: "WATER_FOUNTAIN"}
    ];

    var OUTDOOR_CATEGORIES = [
        {type: "outdoor", id: "7", name: "parking", label: "PARKING"},
        {type: "outdoor", id: "171", name: "gas-station", label: "GAS_STATION"},
        {type: "outdoor", id: "16", name: "dining", label: "DINING"},
        {type: "outdoor", id: "91", name: "grocery-stores", label: "GROCERY_STORES"},
        {type: "outdoor", id: "12", name: "pharmacy", label: "PHARMACY"},
        {type: "outdoor", id: "10", name: "banks-atm", label: "BANKS_ATM"},
        {type: "outdoor", id: "208", name: "gym", label: "GYM"},
        {type: "outdoor", id: "237", name: "retail", label: "RETAIL"},
        {type: "outdoor", id: "327", name: "postal-services", label: "POSTAL_SERVICES"},
        {type: "outdoor", id: "189", name: "pet-boarding", label: "PET_BOARDING"},
        {type: "outdoor", id: "39", name: "library", label: "LIBRARY"},
        {type: "outdoor", id: "199", name: "services", label: "SERVICES"},
        {type: "outdoor", id: "37", name: "hotel-accommodation", label: "HOTEL_ACCOMMODATION"},
        {type: "outdoor", id: "36", name: "place-of-worship", label: "PLACE_OF_WORSHIP"},
        {type: "outdoor", id: "326", name: "museums", label: "MUSEUMS"},
        {type: "outdoor", id: "298", name: "park", label: "PARK"}
    ];

    return {
        getAllCategoriesArray: function () {
            return ALL_CATEGORIES;
        },

        getOutdoorCategoriesArray: function () {
            return OUTDOOR_CATEGORIES;
        },

        getParentCategories: function() {
            var parentCategories = [];
            this.getAllCategoriesArray().forEach(function (item) {
                if (!parentCategories.includes(item.type) && item.type !== 'general') parentCategories.push(item.type);
            });
            return parentCategories;
        },

        getAllCategoriesClasses: function () {
            var categoriesNames = this.getAllCategoriesArray().map(function (category) {
                return category.name
            });
            return categoriesNames.concat(this.getParentCategories()).join(' ');
        }
    };
})();