var AppTranslator = function(pLang,pLabels) {
	langLabels = pLabels;
	lang = pLang;
};

AppTranslator.prototype.cLanguageMap = {
	"en_us": 0,
	"es_es": 1
};

/**
* @private
* @name cManeuverType2Index
* @memberOf AppTranslator#
* @description
* conversion from maneuver string to index.
*/
AppTranslator.prototype.cManeuverType2Index =
{
    'eVgManeuverTypeUnknown': 0,
    'eVgManeuverTypeGoStraight': 1,
    'eVgManeuverTypeTurnGentleRight': 2,
    'eVgManeuverTypeTurnGentleLeft': 3,
    'eVgManeuverTypeTurnRight': 4,
    'eVgManeuverTypeTurnLeft': 5,
    'eVgManeuverTypeTurnSharpRight': 6,
    'eVgManeuverTypeTurnSharpLeft': 7,
    'eVgManeuverTypeUTurnRight': 8,
    'eVgManeuverTypeUTurnLeft': 9,
    'eVgManeuverTypeStart': 10,
    'eVgManeuverTypeEnd': 11,
    'eVgManeuverTypeGoUp': 12,
    'eVgManeuverTypeGoDown': 13,
    'eVgManeuverTypeChangeModality': 14,
    'eVgManeuverTypeChangeLayer': 15,
    'eVgManeuverTypeWaypoint': 16
};

/**
* @private
* @name cStringTable
* @memberOf AppTranslator#
* @description
* translation of some keywords
*/
AppTranslator.prototype.cStringTable =
[
    "FOR",
    "THEN",
    "AND",
    "NEAR",
    "USING",
    "THE_STAIRWAY",
    "THE_ESCALATOR",
    "THE_LIFT"
];

/**
* @private
* @name cTimeStringTable
* @memberOf AppTranslator#
* @description
* time under minute, around a minute, X number of minutes
*/
AppTranslator.prototype.cTimeStringTable =
[
    "A_FEW_SECONDS",
    "ABOUT_A_MINUTE",
    "ABOUT_MINUTES",
];

/**
* @private
* @name cDistanceStringTable
* @memberOf AppTranslator#
* @description
* distance in feet, X number of feet
*/
AppTranslator.prototype.cDistanceStringTable =
[
    "1FEET",
    "FEET"
];

/**
* @private
* @name cNextActionStringTable
* @memberOf AppTranslator#
* @description
* Maneuver to next action string including tokens to be replaced by certain keywords
*/
AppTranslator.prototype.cNextActionStringTable =
[
    "UNKNOWN", // eVgManeuverTypeUnknown
    "NNAV_GO_STRAIGHT",
    "NNAV_TURN_GENTLE_RIGHT",
    "NNAV_TURN_GENTLE_LEFT",
    "NNAV_TURN_RIGHT",
    "NNAV_TURN_LEFT",
    "NNAV_TURN_SHARP_RIGHT",
    "NNAV_TURN_SHARP_LEFT",
    "NNAV_MAKE_RIGHT_U_TURN",
    "NNAV_MAKE_LEFT_U_TURN",
    "NNAV_START",
    "NNAV_YOU_HAVE_ARRIVED",
    "NNAV_GO_UP",
    "NNAV_GO_DOWN",
    "NNAV_CHANGE_TRANSPORTATION_MODE",
    "NNAV_CHANGE_BUILDINGS", // Layer change, it could be buildings, zone, inside/outside....
    "NNAV_STOP" // Waypoint maneuver type
];

/**
* @private
* @name cActionStringTable
* @memberOf AppTranslator#
* @description
* Maneuver to action string including tokens to be replaced by certain keywords
*/
AppTranslator.prototype.cActionStringTable =
[
    "UNKNOWN", // eVgManeuverTypeUnknown
    "NAV_GO_STRAIGHT",
    "NAV_TURN_GENTLE_RIGHT",
    "NAV_TURN_GENTLE_LEFT",
    "NAV_TURN_RIGHT",
    "NAV_TURN_LEFT",
    "NAV_TURN_SHARP_RIGHT",
    "NAV_TURN_SHARP_LEFT",
    "NAV_MAKE_RIGHT_U_TURN",
    "NAV_MAKE_LEFT_U_TURN",
    "NAV_START",
    "NAV_YOU_HAVE_ARRIVED",
    "NAV_GO_UP_TO_FLOOR",
    "NAV_GO_DOWN_TO_FLOOR",
    "NAV_USE_TRANSPORTATION_MODE",
    "NAV_CHANGE_BUILDINGS",
	"NAV_STOP_AT_WAYPOINT"
];

AppTranslator.prototype.cJsonType2Index =
{
    'settings':0,
    'floors': 1,
    'rooms': 2,
    'physicians': 3,
    'pois': 4,
    'buildings': 5,
    'classifications': 6,
    'institutions': 7,
    'sites': 8,
    'waypoints': 9,
    'parking_planner': 10,
    'outside_locations': 11,
    'departments': 12,
    // 'patient_care_units':13,
    'patient_services':13,
    'clinics':14,
    'conference_rooms':15,
    'time_exclusions':16,
    // 'outside_location_rel_user_types':18,
    // 'waypoint_user_types':19
};

AppTranslator.prototype.cJsonFilesTable =
[
    "../map/sqlite/json/app_settings",
    "../map/sqlite/json/floors",
    "../map/sqlite/json/rooms",
    "../map/sqlite/json/physicians",
    "../map/sqlite/json/pois",
    "../map/sqlite/json/buildings",
    "../map/sqlite/json/classifications",
    "../map/sqlite/json/institutions",
    "../map/sqlite/json/sites",
    "../map/sqlite/json/waypoints",
    "../map/sqlite/json/parking_planner",
    "../map/sqlite/json/outside_locations",
    "../map/sqlite/json/departments",
    // "../map/sqlite/json/patient_care_units", // HSS-418 there were duplicates with ../map/sqlite/json/pois
    "../map/sqlite/json/patient_services",
    "../map/sqlite/json/clinics",
    "../map/sqlite/json/conference_rooms",
    "../map/sqlite/json/time_exclusions",
    // "sqlite/json/outside_location_rel_user_types",
    // "sqlite/json/waypoint_user_types"
];

AppTranslator.prototype.getLangLabel = function(key){
	return langLabels[key];
}

AppTranslator.prototype.getFileUrl = function(key){
	var index = this.cJsonType2Index[key];
	var extension = '_'+lang+'.json';
	return this.cJsonFilesTable[index]+extension;
}

AppTranslator.prototype.getDBDataFiles = function(){
    return this.cJsonType2Index;
}

AppTranslator.prototype.getLanguageIndex = function(){
	return this.cLanguageMap(lang);
}

AppTranslator.prototype.getcStringTable = function(index){
    var key = this.cStringTable[index];
    return langLabels[key];
}

AppTranslator.prototype.getcTimeStringTable = function(index){
    var key = this.cTimeStringTable[index];
    return langLabels[key];
}

AppTranslator.prototype.getcDistanceStringTable = function(index){
    var key = this.cDistanceStringTable[index];
    return langLabels[key];
}

AppTranslator.prototype.getcNextActionStringTable = function(index){
    var key = this.cNextActionStringTable[index];
    return langLabels[key];
}

AppTranslator.prototype.getcActionStringTable = function(index){
    var key = this.cActionStringTable[index];
    return langLabels[key];
}

